import { ThemeProvider } from "@material-ui/core/styles";
import "assets/css/style.css";
import LogOutModal from "components/LogOutModal/LogOutModal";
import NotificationBar from "components/NotificationBar";
import theme from "core/Theme";
import routes, { AppRoute } from "core/routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NotificationBar />
        <LogOutModal />
        <Switch>
          {routes.map((route) => (
            <AppRoute key={route.path} {...route} />
          ))}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
