import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  button: {
    width: "100%",
    margin: "25px 0",
    borderRadius: "5px",
    padding: "10px",
    fontWeight: "500",
  },
});
const CustomButton = ({
  children,
  className,
  variant,
  color,
  type,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      className={classes.button}
      style={{ fontWeight: "500" }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
