import authRoles from "core/auth/authRoles";
import ThemeManagement from "./ThemeManagement";

const themeManagementConfig = {
  component: ThemeManagement,
  path: "/themes",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
};

export default themeManagementConfig;
