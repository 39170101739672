import authRoles from "core/auth/authRoles";
import DashBoard from "./DashBoard";

const homeConfig = {
  component: DashBoard,
  path: "/users",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
};

export default homeConfig;
