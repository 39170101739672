import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Logo from "assets/images/svg/everycred-logo.svg";
// import { BiTachometer, BiLogOut } from "react-icons/bi";
// import { ReactComponent as Dashboard } from "../../../assets/images/svg/Insights.svg";
// import { ReactComponent as Credentials } from "../../../assets/images/svg/Credentials.svg";
import { ReactComponent as Issuers } from "../../../assets/images/svg/Issuers.svg";
// import { ReactComponent as Templates } from "../../../assets/images/svg/Templates.svg";
// import { ReactComponent as Logs } from "../../../assets/images/svg/Logs.svg";
// import { ReactComponent as Settings } from "../../../assets/images/svg/Settings.svg";
// import { ReactComponent as About } from "../../../assets/images/svg/About.svg";
import { ReactComponent as Logout } from "../../../assets/images/svg/Logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleLogoutModal } from "store/commonActions";
import Heading from "../Midbar/components/Heading";
import Action from "../Midbar/components/Action";
import Body from "../Midbar/components/Body";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import {
  setPathForSidebar,
  showMidbarAction,
  showSidebarAction,
} from "./action";

const useStyles = makeStyles((theme) => ({
  paperProps: {
    width: "60%",
  },
  siteAside: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 4,
    "& ::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transperent",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "106px",
    },
  },
  sidebarLeftSide: {
    borderRight: "none",
    boxShadow: "1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.1)",
    background: "#f6f6f6",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      padding: "15px 15px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "26px 18px 18px",
    },
  },
  sidebarLogo: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xl")]: {
      margin: "0 auto 70px",
      width: "36px",
      height: "44px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 30px",
      width: 80,
      height: 70,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0 20px",
      width: "100%",
      // height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 auto 60px",
      width: "42px",
      height: "48px",
    },
    "& img": {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "60%",
      },
    },
  },
  sideBarNav: {
    padding: 0,
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0 10px",
    // },
  },
  sidebarNavLi: {
    padding: 0,
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 20px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 18px",
    },
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "16px",
    },
  },
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    // flexDirection: "column",
    // border: "1px solid #d5d5d5",
    background: "#ffffff",
    // borderRadius: "20px",
    color: "#848484",
    width: "100%",
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      margin: "5px 0",
      borderRadius: 0,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      flexDirection: "row",
      border: "none",
      padding: "5px 15px",
    },
    "&:hover": {
      color: "#ffffff",
      background: "#083f85",
      "& .nav-icon-wrap": {
        background: "#ffffff",
        "& i": {
          color: "#083f85",
        },
      },
    },
  },
  activeLink: {
    color: "#ffffff",
    background: "#083f85",
    "& .nav-icon-wrap": {
      background: "#ffffff",
      "& i": {
        color: "#083f85",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    "&::after": {
      [theme.breakpoints.down("xl")]: {
        right: "-15px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        right: "-18px",
      },
      content: `' '`,
      position: "absolute",
      backgroundColor: "#083f85",
      width: 3,
      height: 25,
      top: "50%",
      transform: "translate(0, -50%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      left: "97%",
      top: "50%",
      transform: "translate(0, -50%)",
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      [theme.breakpoints.down("xl")]: {
        borderLeft: "8px solid #083f85",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        borderLeft: "12px solid #083f85",
      },
    },
  },
  sidebarNavItemText: {
    fontSize: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    display: "Block",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 10,
    },
  },
  iconWrap: {
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#f6f6f6",
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 6px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      background: "#083f85",
    },
  },
  midbarContentWrapper: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },
  headerMid: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "baseline",
    },
  },
  headingTitle: {
    display: "flex",
    alignItems: "baseline",
  },
}));

function SidebarNavItem({ path, itemName, icon, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSidebarSelection = () => {
    dispatch(setPathForSidebar(path));
    if (!path.includes("users") && !path.includes("about")) {
      dispatch(showMidbarAction(true));
    } else {
      history.push(path);
      handleClose();
    }
  };

  return (
    <div className={classes.sidebarNavItem} onClick={handleSidebarSelection}>
      <div className={`${classes.iconWrap} nav-icon-wrap`}>
        <i className={`${classes.sidebarNavItemIcon}`}>{icon}</i>
      </div>
      <span className={classes.sidebarNavItemText}>{itemName}</span>
    </div>
  );
}

function SidebarWithMidbar({
  display,
  openDrawer,
  handleClose,
  setOpenDrawer,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [showMidbar, setShowMidbar] = useState(false);
  let { pathname } = useLocation();
  const didMount = useRef(false);
  const sidebarPathForMobile = useSelector((state) => state.sidebarWithMidbar);
  const { showMidbar } = sidebarPathForMobile;

  useEffect(() => {
    if (didMount.current) {
      setOpenDrawer(false);
    } else didMount.current = true;
  }, [setOpenDrawer, pathname]);

  if (!display) return null;

  const handleLogout = (event) => {
    dispatch(toggleLogoutModal());
  };

  const showSidebarMenu = () => {
    dispatch(showSidebarAction(true));
  };

  return (
    <>
      <Drawer
        variant="temporary"
        className={classes.siteAside}
        open={openDrawer}
        onClose={handleClose}
        classes={{
          paperAnchorDockedLeft: classes.sidebarLeftSide,
          paper: classes.paperProps,
        }}
      >
        <i className={classes.sidebarLogo}>
          <img src={Logo} alt="Everycred Logo" />
        </i>
        {showMidbar ? (
          <div className={classes.midbarContentWrapper}>
            <div className={classes.headingTitle}>
              <IoMdArrowRoundBack onClick={showSidebarMenu} />
              <Heading />
            </div>
            <Action />
            <Body />
          </div>
        ) : (
          <List className={classes.sideBarNav}>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path="/users"
                itemName="Users"
                icon={<Issuers />}
                handleClose={handleClose}
              />
            </ListItem>
            {/* <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/credentials'
                itemName='Credentials'
                icon={<Credentials />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/issuers'
                itemName='Issuers'
                icon={<Issuers />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/subjects'
                itemName='Subjects'
                icon={<Templates />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/activities'
                itemName='Logs'
                icon={<Logs />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/settings'
                itemName='Settings'
                icon={<Settings />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi}>
              <SidebarNavItem
                path='/about'
                itemName='About'
                icon={<About />}
                handleClose={handleClose}
              />
            </ListItem> */}
            <ListItem className={classes.sidebarNavLi} onClick={handleLogout}>
              <div className={classes.sidebarNavItem}>
                <div className={`${classes.iconWrap} nav-icon-wrap`}>
                  <i className={`${classes.sidebarNavItemIcon}`}>
                    <Logout />
                  </i>
                </div>
                <span className={classes.sidebarNavItemText}>Logout</span>
              </div>
            </ListItem>
          </List>
        )}
      </Drawer>
    </>
  );
}

export default React.memo(SidebarWithMidbar);
