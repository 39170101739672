import {
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS,
  GET_RECENTLY_VERIFIED_RECORD_BEGIN,
  GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  GET_RECENTLY_VERIFIED_RECORD_FAILURE,
} from "./header.constant";

const initState = {
  loading: false,
  data: {},
  error: "",
};

const initialState = {
  loading: false,
  data: {},
  error: "",
};

export const getUserDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export const getRecentlyVerifiedRecordsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_RECENTLY_VERIFIED_RECORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_RECENTLY_VERIFIED_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case GET_RECENTLY_VERIFIED_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
