import {
  SET_PATH_FOR_SIDEBAR,
  SHOW_MIDBAR,
  SHOW_SIDEBAR,
  CLEAR_PATH_FOR_SIDEBAR,
} from "./constants";

export const setPathForSidebar = (payload) => ({
  type: SET_PATH_FOR_SIDEBAR,
  payload: payload,
});

export const clearPathForSidebar = (payload) => ({
  type: CLEAR_PATH_FOR_SIDEBAR,
  payload: payload,
});

export const showSidebarAction = (payload) => ({
  type: SHOW_SIDEBAR,
  payload: payload,
});

export const showMidbarAction = (payload) => ({
  type: SHOW_MIDBAR,
  payload: payload,
});
