import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SOCIAL_LOGIN_BEGIN,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
} from "./Login.constant";

import { http, headers } from "utils";

const loginBegin = () => ({ type: LOGIN_BEGIN });

const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });

const loginFailure = (payload) => ({ type: LOGIN_FAILURE, payload });

export const login = (userData, successCB, FailureCB) => (dispatch) => {
  dispatch(loginBegin());
  http("post", "/login", userData, headers)
    .then((response) => {
      dispatch(loginSuccess(response.data.data.token));
      successCB(response.data.data.token);
    })
    .catch((err) => {
      dispatch(loginFailure(err.response?.data?.message));
      FailureCB(err.response?.data?.message);
    });
};

// Social Login : Google, LinkedIn, Mircrosoft

const socialLoginBegin = () => ({ type: SOCIAL_LOGIN_BEGIN });

export const socialLoginSuccess = (payload) => ({
  type: SOCIAL_LOGIN_SUCCESS,
  payload,
});

const socialLoginFailure = (payload) => ({
  type: SOCIAL_LOGIN_FAILURE,
  payload,
});

export const socialLogin =
  (params, data, successCB, failureCB) => (dispatch) => {
    dispatch(socialLoginBegin());
    http("post", "/login/sso", data, { headers, params })
      .then((response) => {
        dispatch(socialLoginSuccess(response.data.data.token));
        successCB(response.data.data.token);
      })
      .catch((err) => {
        dispatch(socialLoginFailure(err.response?.data?.message));
        failureCB(err.response?.data?.message);
      });
  };
