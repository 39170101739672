import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Pagination from "@material-ui/lab/Pagination";
import CustomTooltip from "components/CustomTooltip";
import theme from "core/Theme";
import styles from "core/customstyle.module.css";
import React, { useRef, useState } from "react";
import { getDate } from "utils";
import AcceptUser from "../Accept";
import RejectUser from "../Reject";

const useStyles = makeStyles((theme) => ({
  emailCol: {
    maxWidth: 150,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dateWidth: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 125,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 180,
    },
  },
  verificationCol: {
    minWidth: 120,
  },
  cadidateName: {
    textOverflow: "ellipsis",
    maxWidth: 100,
    overflow: "hidden",
    whiteSpace: "noWrap",
  },
  actionRow: {
    display: "flex",
  },
}));

const userStatus = {
  pending: "Pending",
  accept: "Accepted",
  decline: "Rejected",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, label: "Name" },
  { id: "email", numeric: false, label: "Email" },
  { id: "contact", numeric: true, label: "Contact" },
  { id: "created_at", numeric: false, label: "Requested Date" },
  { id: "status", numeric: false, label: "Status" },
  { id: "actions", numeric: false, label: "Actions" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    if (property !== "actions") {
      onRequestSort(event, property);
    }
  };
  let newHeadCells = [...headCells];
  return (
    <TableHead>
      <TableRow className={styles.headerRow}>
        {newHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.id === "actions"}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function RecordTable({
  records,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  showCount,
  showSubjectName,
  currentClass,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("candidate_name");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    handleChangeRowsPerPage(event.target.value);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const actionIcon = (user) => {
    let { status } = user;
    return (
      <div className={classes.actionRow}>
        {status !== "decline" ? <RejectUser user={user} /> : null}
        {status !== "accept" ? <AcceptUser user={user} /> : null}
      </div>
    );
  };

  let emptyTable = (
    <>
      <EnhancedTableHead
        classes={classes}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={7}>
            No Record Found !
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div className={styles.tableRoot}>
          <Paper className={styles.tablePaper} elevation={0}>
            <TableContainer>
              <Table
                className={`${[classes.table, currentClass].join(" ")}`}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                {records ? (
                  <>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={records.length}
                      showCount={showCount}
                      showSubjectName={showSubjectName}
                    />
                    <TableBody>
                      {stableSort(records, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              <CustomTooltip title={row.name}>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  className={classes.cadidateName}
                                >
                                  {row.name}
                                </TableCell>
                              </CustomTooltip>
                              <CustomTooltip title={row.email}>
                                <TableCell
                                  align="left"
                                  className={classes.emailCol}
                                >
                                  {row.email}
                                </TableCell>
                              </CustomTooltip>

                              <TableCell
                                align="left"
                                className={classes.dateCol}
                              >
                                {row.contact || "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.dateCol}
                              >
                                {getDate(row.created_at)}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.dateCol}
                              >
                                {userStatus[row.status]}
                              </TableCell>

                              <TableCell align="left">
                                <div className={styles.iconsContainer}>
                                  {actionIcon(row)}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </>
                ) : (
                  emptyTable
                )}
              </Table>
            </TableContainer>
          </Paper>
          {records ? (
            <div className={styles.pagination}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className={styles.recordBtn}
                >
                  {rowsPerPage} Rows
                  <KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem value="5" onClick={handleClose}>
                              5 Rows
                            </MenuItem>
                            <MenuItem value="10" onClick={handleClose}>
                              10 Rows
                            </MenuItem>
                            <MenuItem value="25" onClick={handleClose}>
                              25 Rows
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Pagination
                count={Math.ceil(total / rowsPerPage)}
                page={page}
                defaultPage={1}
                onChange={handleChangePage}
                siblingCount={1}
              />
            </div>
          ) : null}
        </div>
      </MuiThemeProvider>
    </>
  );
}

export default React.memo(RecordTable);
