import { makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import ImageCropper from "components/ImageCropper";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";
import styles from "../../../../core/customstyle.module.css";

const useStyles = makeStyles((theme) => ({
  browseLink: {
    cursor: "pointer",
    color: "#083f85",
  },
  dropzoneText: {
    marginTop: "5px",
    marginBottom: "0",
    color: "#bababa",
    textAlign: "center",
  },
}));

const dropzoneStyle = {
  flex: 1,
  marginTop: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 4,
  borderColor: "#9f9f9f",
  borderStyle: "dashed",
  backgroundColor: "#f6f6f6",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#083f85",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const thumbButton = {
  position: "absolute",
  right: 10,
  bottom: 10,
  background: "rgba(0,0,0,.8)",
  color: "#fff",
  border: 0,
  borderRadius: ".325em",
  cursor: "pointer",
};

const IMAGE_MAX_SIZE = 1000000;

export default function ThemeImageField({
  themeData,
  setErrors,
  setThemeData,
  isEditTheme,
}) {
  const classes = useStyles();
  const [themeImage, setThemeImage] = useState([]);
  const [imageSrc, setImageSrc] = React.useState("");
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    aspect: 16 / 9,
  });
  const croppedImage = themeData.theme_image?.[0];

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".jpg,.jpeg,.png",
    multiple: false,
    getFilesFromEvent: async (event) => {
      const files = event.target?.files || event.dataTransfer?.files || [];
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url;
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    maxFiles: 1,
    maxSize: IMAGE_MAX_SIZE,
    onDrop: (acceptedFiles, fileRejections, event) => {
      setThemeImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if (Boolean(acceptedFiles.length)) {
        let img = acceptedFiles[0];
        const aspect = img.width / img.height;
        let maxWidth = img.width;
        let maxHeight = img.height;

        const height = (maxHeight * 100) / img.height;
        const width = (maxWidth * 100) / img.width;

        setCrop({
          unit: "%",
          width: width,
          height: height,
          x: (100 - width) / 2,
          y: (100 - height) / 2,
          aspect,
        });
        setErrors((errors) => ({ ...errors, theme_image: "" }));
        setThemeData((themeData) => ({
          ...themeData,
          theme_image: acceptedFiles,
        }));
      }
      if (Boolean(fileRejections.length)) {
        setErrors((errors) => ({
          ...errors,
          theme_image: fileRejections[0].errors[0].message,
        }));
      }
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors((errors) => ({
              ...errors,
              theme_image: "Logo Size is larger than 1MB!",
            }));
          }
          if (err.code === "file-invalid-type") {
            setErrors((errors) => ({
              ...errors,
              theme_image: "Please Upload Valid Image Format!",
            }));
          }
          setThemeImage([]);
        });
      });
    },
  });

  const setFileAndOpenCropper = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setErrors((errors) => ({ ...errors, theme_image: "" }));
      setImageSrc(reader.result);
      setOpen(true);
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (Boolean(themeImage.length)) {
      setFileAndOpenCropper(themeImage[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeImage]);

  const handleEdit = (event, file) => {
    event.preventDefault();
    event.stopPropagation();
    setFileAndOpenCropper(file);
  };

  const handleCroppedImage = (image) => {
    setThemeData((themeData) => ({ ...themeData, theme_image: image }));
  };

  const newImagePreview = themeImage.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={croppedImage?.preview ? croppedImage?.preview : file.preview}
          style={img}
          alt={file.name}
        />
        <button
          type="button"
          style={thumbButton}
          onClick={(event) => handleEdit(event, file)}
        >
          Edit
        </button>
      </div>
    </div>
  ));

  const oldImagePreview = () => (
    <div style={thumb}>
      <div style={thumbInner}>
        <img src={themeData.theme_image?.[0]} style={img} alt="Theme" />
      </div>
    </div>
  );

  const previewImage =
    isEditTheme && !themeImage.length ? oldImagePreview() : newImagePreview;

  useEffect(
    () => () => {
      themeImage.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [themeImage]
  );

  const style = useMemo(
    () => ({
      ...dropzoneStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className={classes.issuerMain}>
      <ImageCropper
        crop={crop}
        setCrop={setCrop}
        src={imageSrc}
        setSrc={setImageSrc}
        open={open}
        setOpen={setOpen}
        handleCroppedImage={handleCroppedImage}
      />

      <InputLabel htmlFor="themeImage" className={styles.fwMedium} required>
        Theme Image
      </InputLabel>

      <section>
        <div {...getRootProps({ style, className: "dropzone" })}>
          <input {...getInputProps()} />
          <AiOutlineCloudUpload size={30} color="#083f85" />
          <p className={classes.dropzoneText}>
            Drag & drop or{" "}
            <span className={classes.browseLink} color="primary">
              Browse
            </span>
          </p>
          <p className={classes.dropzoneText}>
            Please upload image in .jpg, .jpeg or .png format. Max Size Of 1MB.
          </p>
        </div>
        <aside style={thumbsContainer}>{previewImage}</aside>
      </section>
    </div>
  );
}
