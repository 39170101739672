import {
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS,
  GET_RECENTLY_VERIFIED_RECORD_BEGIN,
  GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  GET_RECENTLY_VERIFIED_RECORD_FAILURE,
} from "./header.constant";
import { http, headers } from "utils";

const getUserDetailsBegin = () => ({ type: GET_USER_DETAILS_BEGIN });

const getUserDetailsSuccess = (payload) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload,
});

const getUserDetailsFailure = (payload) => ({
  type: GET_USER_DETAILS_FAILURE,
  payload,
});

export const getUserDetails = () => (dispatch) => {
  dispatch(getUserDetailsBegin());
  http("get", "/user", null, { headers }, true)
    .then((response) => {
      dispatch(getUserDetailsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getUserDetailsFailure(err.response?.data?.message));
    });
};

export const setUserDetails = (payload) => ({
  type: SET_USER_DETAILS,
  payload,
});
const getRecentlyVerifiedRecordBegin = () => ({
  type: GET_RECENTLY_VERIFIED_RECORD_BEGIN,
});

const getRecentlyVerifiedRecordSuccess = (payload) => ({
  type: GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  payload,
});
const getRecentlyVerifiedRecordFailure = (payload) => ({
  type: GET_RECENTLY_VERIFIED_RECORD_FAILURE,
  payload,
});

export const getRecentlyVerifiedRecords = (params) => (dispatch) => {
  dispatch(getRecentlyVerifiedRecordBegin());
  http("get", "/user/credential/verified", null, { headers, params }, true)
    .then((response) => {
      dispatch(getRecentlyVerifiedRecordSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getRecentlyVerifiedRecordFailure());
    });
};
