import {
  GET_USER_LIST_BEGIN,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  USER_ACTION_BEGIN,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILURE,
} from "./dashBoard.constant";

const initState = {
  userList: {
    loading: false,
    response: [],
    error: "",
  },
  userAction: {
    loading: false,
    response: {},
    error: "",
  },
};

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_LIST_BEGIN:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: true,
        },
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: false,
          response: action.payload,
        },
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        userList: {
          ...state.userList,
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case USER_ACTION_BEGIN:
      return {
        ...state,
        userAction: {
          ...state.userAction,
          loading: true,
        },
      };
    case USER_ACTION_SUCCESS:
      return {
        ...state,
        userAction: {
          ...state.userAction,
          loading: false,
          response: {},
        },
      };
    case USER_ACTION_FAILURE:
      return {
        ...state,
        userAction: {
          ...state.userAction,
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
