import dashBoardConfig from "pages/DashBoard/dashBoardConfig";
import loginConfig from "pages/Login/loginConfig";
import themeManagementConfig from "pages/ThemeManagement/themeManagementConfig";
import noMatchConfig from "pages/NoMatch/noMatchConfig";

const routes = [
  loginConfig,
  dashBoardConfig,
  themeManagementConfig,
  noMatchConfig,
];

export default routes;
