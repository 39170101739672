import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  headingMain: {
    textAlign: "left",
  },
  title: {
    marginBottom: "2px",
  },
  about: {
    color: "#6b6b6b",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
}));

const Heading = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  let title = "Issuers";
  let about = "Credential Issuer Profiles";
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );

  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/subjects"
      : path.match("/subjects")
  ) {
    title = "Subjects";
    about = "All Credential Subjects";
  }
  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/activities"
      : path.match("/activities")
  ) {
    title = "Issuance Activities​";
    about = null;
  }

  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/settings"
      : path.match("/settings")
  ) {
    title = "Account Settings​";
    about = null;
  }

  return (
    <div className={classes.headingMain}>
      <Typography variant='h3' className={classes.title} color='primary'>
        {title}
      </Typography>
      <Typography variant='body2' className={classes.about}>
        {about}
      </Typography>
    </div>
  );
};

export default Heading;
