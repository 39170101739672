import {
  SET_PATH_FOR_SIDEBAR,
  SHOW_SIDEBAR,
  SHOW_MIDBAR,
  CLEAR_PATH_FOR_SIDEBAR,
} from "./constants";

const initState = {
  response: "",
  showMidbar: false,
  showSidebar: false,
};

export function sidebarWithMidbarReducer(state = initState, action) {
  switch (action.type) {
    case SET_PATH_FOR_SIDEBAR:
      return {
        ...state,
        response: action.payload,
      };
    case CLEAR_PATH_FOR_SIDEBAR:
      return {
        ...state,
        response: "",
      };

    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
        showMidbar: false,
      };
    case SHOW_MIDBAR:
      return {
        ...state,
        showMidbar: action.payload,
        showSidebar: false,
      };
    default:
      return state;
  }
}
