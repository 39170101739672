import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import AddEditTheme from "./components/AddEditTheme/AddEditTheme";
import ThemeFields from "./components/ThemeFields/ThemeFields";
import ThemeListing from "./components/ThemeListing/ThemeListing";

const useStyles = makeStyles((theme) => ({
  headingBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  saveBtn: {
    padding: "10px 20px",
  },
}));

export default function ThemeManagement() {
  const classes = useStyles();
  const [addEditDialog, setAddEditDialog] = useState(false);

  const openAddEditDialog = () => {
    setAddEditDialog(true);
  };

  const closeOpenDialog = () => {
    setAddEditDialog(false);
  };

  return (
    <Switch>
      <Route exact path="/themes/:themeId/fields">
        <ThemeFields />
      </Route>
      <Route exact path="/themes">
        <div>
          <div className={classes.headingBox}>
            <h2>Theme Management</h2>
            <Button
              onClick={openAddEditDialog}
              color="primary"
              className={classes.button}
            >
              Add Theme
            </Button>
          </div>
          <ThemeListing />
          <AddEditTheme open={addEditDialog} handleClose={closeOpenDialog} />
        </div>
      </Route>
    </Switch>
  );
}
