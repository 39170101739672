import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Checkbox,
  makeStyles,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomButton from "components/Button";
// import SocialLogin from "../SocialLogin";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import AppLogo from "components/AppLogo";
// import RedirectComponent from "components/RedirectComponent";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex, getCookie } from "utils";
import { login } from "../../Login.action";
import Loader from "components/Loader";
import { openNotification } from "store/commonActions";
import styles from "core/customstyle.module.css";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    marginBottom: "25px 0",
    borderRadius: "5px",
    padding: "10px",
    fontWeight: "500",
  },
  preLoginContentWrapper: {
    maxWidth: "490px",
    margin: "0 auto",
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
    "& label": {
      textAlign: "left",
      marginBottom: "5px",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
    "& input": {
      [theme.breakpoints.down("lg")]: {
        height: "45px !important",
      },
      [theme.breakpoints.down("sm")]: {
        height: "40px !important",
      },
    },
  },

  checkbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "15px",
      paddingLeft: "5px",
      letterSpacing: "normal",
      color: "#616161",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "500",
      fontSize: "15px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.19",
      letterSpacing: "normal",
      color: " #083f85",
    },
  },
  rememberContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 9,
  },
  checkedIcon: {
    root: {
      "&$checked": {
        color: "black",
      },
    },
    checked: {},
  },
  reponseError: {
    marginTop: "15px",
    textAlign: "center",
    wordBreak: "break-word",
    "& p": {
      color: "red",
      fontWeight: "500",
      fontSize: "13.5px",
      "-webkit-line-clamp": 2,
      overflow: "hidden",
      "-webkit-box-orient": "vertical",
      display: "-webkit-box",
    },
  },
  customLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#616161",
  },
}));

const LoginForm = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState({
    emailError: {
      helperText: "",
      errorStatus: false,
    },
    passwordError: {
      helperText: "",
      errorStatus: false,
    },
    responseError: {
      errorStatus: false,
      helperText: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { normalLogin, SSOLogin } = useSelector((state) => state.login);

  useEffect(() => {
    const rememberMe = getCookie("rememberMe");
    if (rememberMe) {
      if (JSON.parse(rememberMe)) {
        const user = JSON.parse(decodeURIComponent(getCookie("user")));
        setData({
          email: user.email,
          password: user.password,
        });
        setRememberMe(JSON.parse(rememberMe));
      }
    }
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;
    switch (name) {
      case "email":
        setData({
          ...data,
          email: value,
        });
        setError({
          ...error,
          responseError: {
            errorStatus: false,
            helperText: "",
          },
          emailError: {
            errorStatus: false,
            helperText: "",
          },
        });
        if (value === "") {
          setError({
            ...error,
            emailError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        }
        break;
      case "password":
        setData({
          ...data,
          password: value,
        });
        setError({
          ...error,
          responseError: {
            errorStatus: false,
            helperText: "",
          },
          passwordError: {
            errorStatus: false,
            helperText: "",
          },
        });
        if (value === "") {
          setError({
            ...error,
            passwordError: {
              errorStatus: true,
              helperText: "*This field is required!",
            },
          });
        }
        break;
      default:
        setData(data);
    }
  };
  const handlePasswordVisibility = (event) => {
    setShowPassword(!showPassword);
  };

  const handlecheckbox = (event) => {
    setRememberMe(!rememberMe);
  };

  const validate = () => {
    let { email } = data;
    if (emailRegex.test(email)) {
      return true;
    } else {
      setError({
        ...error,
        emailError: {
          helperText: "Field Must Contain Valid Email",
          errorStatus: true,
        },
      });
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      validate() &&
      !error.responseError?.errorStatus &&
      !error.passwordError?.errorStatus
    ) {
      const successCB = (token) => {
        setData({
          email: "",
          password: "",
        });

        dispatch(
          openNotification({
            message: "You are now logged in!",
          })
        );
        localStorage.setItem("token", token);
        // document.cookie = `userRole=${user_type}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`;
        history.push("/users");
      };
      const FailureCB = (err) => {
        if (err) {
          setError({
            ...error,
            responseError: {
              errorStatus: true,
              helperText: "Email Or Password Is Invalid!",
            },
          });
        }
      };
      dispatch(login(data, successCB, FailureCB));
      document.cookie = `rememberMe=${rememberMe}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`;
      document.cookie = rememberMe
        ? `user=${encodeURIComponent(
            JSON.stringify(data)
          )}; expires=Thu, 18 Dec 2093 12:00:00 UTC; path=/`
        : "";
    }
  };

  let { email, password } = data;
  let { emailError, passwordError, responseError } = error;
  let { loading } = normalLogin;

  let errorInResponse = responseError?.errorStatus ? (
    // <CustomTooltip title={responseError.helperText}>
    <div className={classes.reponseError}>
      <Typography variant="body1"> {responseError?.helperText} </Typography>
    </div>
  ) : // </CustomTooltip>
  null;

  const token = localStorage.getItem("token");
  if (token) {
    history.push("/users");
  }

  return (
    <div className={classes.preLoginContentWrapper}>
      <AppLogo />
      <form onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <InputLabel className={classes.customLabel}> Email </InputLabel>
          <TextField
            type="email"
            name="email"
            variant="outlined"
            className={styles.primaryInput}
            placeholder="Enter Email Address"
            size="small"
            value={email}
            onChange={handleChange}
            error={emailError?.errorStatus}
            helperText={emailError?.helperText}
            required
          />
        </div>
        <div className={classes.fields}>
          <InputLabel className={classes.customLabel}> Password </InputLabel>
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            className={styles.primaryInput}
            variant="outlined"
            placeholder="Enter Your Password"
            size="small"
            value={password}
            onChange={handleChange}
            error={passwordError?.errorStatus}
            helperText={passwordError?.helperText}
            required
            InputProps={{
              endAdornment: (
                <>
                  <div
                    onClick={handlePasswordVisibility}
                    style={{ cursor: "pointer", paddingTop: "3px" }}
                  >
                    {!showPassword ? (
                      <VisibilityOffOutlinedIcon
                        fontSize="small"
                        color="primary"
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        fontSize="small"
                        color="primary"
                      />
                    )}
                  </div>
                </>
              ),
            }}
          />
        </div>
        <div className={classes.checkbox}>
          <div className={classes.rememberContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{
                    padding: "0",
                  }}
                  onChange={handlecheckbox}
                  checked={rememberMe}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              }
              label="Remember Me"
            />
          </div>
          {/* <div>
            <Link to="/forgetpassword"> Forgot Password ? </Link>
          </div> */}
        </div>
        {errorInResponse}
        <CustomButton
          type="submit"
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={loading || SSOLogin.loading}
        >
          <Loader show={loading || SSOLogin.loading} />{" "}
          {!loading && !SSOLogin.loading ? "Log in" : null}
        </CustomButton>
      </form>
      {/* <SocialLogin />
      <RedirectComponent
        text="Don't Have Account?"
        path="/register"
        name="Register Here"
      /> */}
    </div>
  );
};

export default LoginForm;
