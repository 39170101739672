import { headers, http } from "utils";
import {
  GET_USER_LIST_BEGIN,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_SUCCESS,
  USER_ACTION_BEGIN,
  USER_ACTION_FAILURE,
  USER_ACTION_SUCCESS,
} from "./dashBoard.constant";

const getUserListBegin = () => ({
  type: GET_USER_LIST_BEGIN,
});

const getUserListSuccess = (payload) => ({
  type: GET_USER_LIST_SUCCESS,
  payload,
});

const getUserListFailure = (payload) => ({
  type: GET_USER_LIST_FAILURE,
  payload,
});

export const getUserList = (params) => (dispatch) => {
  dispatch(getUserListBegin());
  http("get", "/admin/users", null, { headers, params }, true)
    .then((response) => {
      dispatch(getUserListSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getUserListFailure(error.response?.data?.message));
    });
};

//Accept or Reject User
const userActionBegin = () => ({
  type: USER_ACTION_BEGIN,
});

const userActionSuccess = (payload) => ({
  type: USER_ACTION_SUCCESS,
  payload,
});

const userActionFailure = (payload) => ({
  type: USER_ACTION_FAILURE,
  payload,
});

export const userAction =
  (user_id, body, successCB, failureCB) => (dispatch) => {
    dispatch(userActionBegin());
    http("post", `/admin/request/${user_id}/status`, body, { headers }, true)
      .then((response) => {
        dispatch(userActionSuccess(response.data.data));
        successCB(response.data.message);
      })
      .catch((error) => {
        dispatch(userActionFailure(error.response?.data?.message));
        failureCB(error.response?.data?.message);
      });
  };
