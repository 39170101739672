import React, { useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import VerifiedIcon from "assets/images/svg/verification-white.svg";
// import AddIcon from "assets/images/svg/plus-white.svg";
import SearchIcon from "assets/images/svg/search-blue.svg";
import DefaultProfilePhoto from "assets/images/DefaultProfilePhoto.jpg";
import VerfiedDrawer from "./components/VerifiedDrawer/VerifiedDrawer";
import PageTitle from "./components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "./header.action";
// import { useHistory } from "react-router";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "components/layout/Sidebar/Sidebar";
import CustomTooltip from "components/CustomTooltip";
// import HeaderMenuList from "../Sidebar/sidebarMenu";
// import HeaderDetailSkeleton from "components/Skeletons/HeaderDetailSkeleton";
import HeaderSkeleton from "components/Skeletons/HeaderSkeleton";
import {
  setPathForSidebar,
  showMidbarAction,
  showSidebarAction,
} from "../SidebarWithMidbar/action";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  siteHeader: {
    display: "flex",
    width: "100%",
    background: "#ffffff",
    zIndex: 3,
  },
  headerContentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 30px 20px 124px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      padding: "15px 15px 0px 15px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "20px 30px 20px 136px",
    },
  },
  headerRight: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "row-reverse",
    },
  },
  headerButton: {
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    backgroundColor: "#083f85",
    borderRadius: "100%",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "#083f85",
    },
    "&.gray-button": {
      backgroundColor: "#ececec",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
  userNameImageWrap: {
    display: "flex",
    alignItems: "center",
  },
  userName: {
    textAlign: "right",
    marginRight: "15px",
    maxWidth: 200,
    textOverflow: "ellipsis",
    "& .normal-text": {
      fontWeight: 400,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconWrap: {
    lineHeight: 0,
    display: "block",
    position: "relative",
    "& img": {
      width: "16px",
      height: "auto",
      paddingBottom: " 3px",
      paddingLeft: "0.5px",
    },
  },
  profileImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "45px",
    height: "45px",
    overflow: "hidden",
    cursor: "pointer",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
  menuBtn: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  menu: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  responsiveMenu: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  dot: {
    backgroundColor: "#f35162",
    width: 7,
    height: 7,
    borderRadius: "50%",
    position: "absolute",
    bottom: 10,
    right: "-3px",
    border: "1px solid #fff",
  },
  uname: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 200,
  },
}));

export default function Header({ display }) {
  // const [notificationEL, SetNotificationEL] = useState(null);
  const [openVerifiedDrawer, setOpenVerifiedDrawer] = useState(false);
  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);
  // const notificationRef = useRef(null);
  const dispatch = useDispatch();
  // const history = useHistory();
  const { path } = useRouteMatch();
  const userDetails = useSelector((state) => state.getUser.data);
  const classes = useStyles();
  const { loading } = useSelector((state) => state.getUser);
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );

  useEffect(() => {
    if (!userDetails.name) {
      dispatch(getUserDetails());
    }
  }, [dispatch, userDetails.name]);

  // const handleNotification = (event) => {
  //   SetNotificationEL(notificationRef.current);
  // };
  // const handleNotificationClose = () => {
  //   SetNotificationEL(null);
  // };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenVerifiedDrawer(open);
  };

  // const handleIssuanceProcess = () => {
  //   history.push("/issuance-process");
  // };

  const handleSidebarClose = useCallback(() => {
    setOpenSidebarDrawer(false);
  }, []);

  const toggleSidebarDrawer = useCallback(() => {
    if (path.match("/users") && path.match("/about")) {
      dispatch(showSidebarAction(true));
    }

    // If we go for mobile device from desktop then midbar content will appear proper using below conditions
    if (!sidebarPathForMobile) {
      if (path.match("/subjects")) {
        dispatch(showMidbarAction(true));
        dispatch(setPathForSidebar("/subjects"));
      } else if (path.match("/credentials")) {
        dispatch(showMidbarAction(true));
        dispatch(setPathForSidebar("/credentials"));
      } else if (path.match("/issuers")) {
        dispatch(showMidbarAction(true));
        dispatch(setPathForSidebar("/issuers"));
      } else if (path.match("/activities")) {
        dispatch(showMidbarAction(true));
        dispatch(setPathForSidebar("/activities"));
      } else if (path.match("/settings")) {
        dispatch(showMidbarAction(true));
        dispatch(setPathForSidebar("/settings"));
      } else if (path.match("/users")) {
        dispatch(showMidbarAction(false));
        dispatch(setPathForSidebar("/users"));
      }
    }
    setOpenSidebarDrawer(!openSidebarDrawer);
  }, [openSidebarDrawer, dispatch, path, sidebarPathForMobile]);

  // const handleViewProfile = (event) => {
  //   history.push("/settings/profile");
  // };

  if (!display) return null;
  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        className={classes.siteHeader}
      >
        <Toolbar className={classes.headerContentWrapper}>
          <div style={{ display: "flex" }}>
            <MenuIcon
              className={classes.menuBtn}
              onClick={toggleSidebarDrawer}
            />
            <PageTitle />
          </div>
          <div className={classes.headerRight}>
            {/* <div className={classes.responsiveMenu}>
              <HeaderMenuList />
            </div> */}
            <div className={classes.menu}>
              {/* <CustomTooltip title="Recently Verified">
                <IconButton
                  className={classes.headerButton}
                  onClick={toggleDrawer(true)}
                >
                  <i className={classes.iconWrap}>
                    <img src={VerifiedIcon} alt="Verify" />
                  </i>
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Issuance Process">
                <IconButton
                  className={classes.headerButton}
                  onClick={handleIssuanceProcess}
                >
                  <i className={classes.iconWrap}>
                    <img src={AddIcon} alt="add" />
                  </i>
                </IconButton>
              </CustomTooltip> */}
              <CustomTooltip title="Search">
                <IconButton
                  className={`${classes.headerButton} gray-button`}
                  style={{ display: "none" }}
                >
                  <i className={classes.iconWrap}>
                    <img src={SearchIcon} alt="search" />
                  </i>
                </IconButton>
              </CustomTooltip>
              {/* <CustomTooltip title="Notifications">
                <IconButton
                  className={`${classes.headerButton} gray-button`}
                  onClick={handleNotification}
                  ref={notificationRef}
                >
                  <i className={classes.iconWrap}>
                    <img src={BellIcon} alt="search" />
                    <Badge variant="dot" className={classes.dot}></Badge>
                  </i>
                </IconButton>
              </CustomTooltip> */}
            </div>
            {/* <NotificationMenu
              notificationEL={notificationEL}
              handleNotificationClose={handleNotificationClose}
            /> */}
            {!loading ? (
              <div className={classes.userNameImageWrap}>
                <div className={classes.userName}>
                  <Typography variant="subtitle2" className="normal-text">
                    Hello,
                  </Typography>
                  <CustomTooltip title={userDetails?.name || ""}>
                    <Typography variant="h6" className={classes.uname}>
                      {userDetails?.name}!
                    </Typography>
                  </CustomTooltip>
                </div>
                <div
                  className={classes.profileImage}
                  // onClick={handleViewProfile}
                >
                  <img
                    src={userDetails.profile_image || DefaultProfilePhoto}
                    alt="User"
                  />
                </div>
              </div>
            ) : (
              <HeaderSkeleton />
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar
        openDrawer={openSidebarDrawer}
        handleClose={handleSidebarClose}
        setOpenDrawer={setOpenSidebarDrawer}
        display={true}
      />
      <VerfiedDrawer open={openVerifiedDrawer} toggleDrawer={toggleDrawer} />
    </>
  );
}
