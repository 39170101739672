import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {
  NavLink,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import { getList, selectItem } from "../../midbar.action";
import styles from "core/customstyle.module.css";
import { getDate } from "utils";
import CustomTooltip from "components/CustomTooltip";
// import { FixedSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
import MidBarList from "components/Skeletons/MidBarList";
import useWidth from "utils/useWidth";

const useStyles = makeStyles((theme) => ({
  issuersList: {
    padding: 0,
  },
  navLink: {
    cursor: "pointer",
    textDecoration: "none",
    width: "100%",
    maxWidth: "360px",
    backgroundColor: "#ffffff",
    border: "solid 1px #d5d5d5",
    borderRadius: "10px",
    display: "flex",
    padding: "20px 20px 20px 30px",
    transition: "all 0.3s ease",
    alignItems: "flex-start",
    "&:hover": {
      backgroundColor: "#083f85",
      "& $issusersLogo": {
        backgroundColor: "#ffffff",
      },
      "& $tabTitle": {
        color: "#fff",
      },
      "& $logoWrapper": {
        backgroundColor: "#fff",
        "& svg": {
          fill: "#083f85",
        },
      },
      "& $issuersDetails": {
        color: "#ffffff",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  issusersLogo: {
    backgroundColor: "#efefef",
    padding: "12px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    flex: "0 0 60px",
    height: 60,
    [theme.breakpoints.down("xl")]: {
      marginRight: "15px",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      width: 50,
      height: 50,
      flex: "0 0 50px",
      marginRight: 10,
    },
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  issuersDetails: {
    wordBreak: "break-all",
    color: "#083f85",
    "& h4": {
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2",
      wordBreak: "break-word",
    },
  },
  listName: {
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
  },
  mailIcon: {
    marginRight: 5,
    position: "relative",
    top: "4px",
    width: "14px",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  activeLink: {
    backgroundColor: "#083f85",
    "& div": {
      color: "#ffffff",
    },
    "& $logoWrapper": {
      backgroundColor: "#fff",
      "& svg": {
        fill: "#083f85",
      },
    },
    "& $tabTitle": {
      color: "#fff",
    },
    "& $issusersLogo": {
      backgroundColor: "#ffffff",
    },
    "&::after": {
      content: `' '`,
      position: "absolute",
      backgroundColor: "#083f85",
      width: 4,
      height: 50,
      top: "50%",
      right: "-30px",
      transform: "translate(0, -50%)",
      borderRadius: "20px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      right: "-18px",
      top: "50%",
      transform: "translate(0, -50%)",
      borderTop: "13px solid transparent",
      borderLeft: "20px solid #083f85",
      borderBottom: "13px solid transparent",
      borderRadius: "0 0 85% 0",
    },
  },
  message: {
    textAlign: "center",
    fontSize: "10px",
    textTransform: "capitalize",
  },
  "@media (max-width: 1680px)": {
    contentWrapper: {
      padding: "20px",
    },
    activeLink: {
      "&::after": {
        right: "-20px",
      },
      "&::before": {
        right: "-15px",
      },
    },
  },
  "@media (max-width: 767px)": {
    activeLink: {
      "&::after": {
        display: "none",
      },
      "&::before": {
        right: "-10px",
      },
    },
  },
  logoWrapper: {
    padding: 7,
    width: 40,
    height: 40,
    backgroundColor: "#083f85",
    borderRadius: "50%",
    border: "solid 0.5px #d5d5d5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fill: "#fff",
    },
  },
  tabTitle: {
    color: "#083f85",
    paddingLeft: 20,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },
}));

function SettingsBody() {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  let { pathname } = useLocation();
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );

  useEffect(() => {
    if (pathname === "/settings") {
      history.push(`${path}/profile`);
    }
  }, [path, history, pathname]);

  return (
    <>
      <ListItem className={[classes.issuersList, styles.mb20].join(" ")}>
        <NavLink
          className={classes.navLink}
          to={
            sidebarPathForMobile
              ? `${sidebarPathForMobile}/profile`
              : `${path}/profile`
          }
          activeClassName={classes.activeLink}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.logoWrapper}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='19.781'
                height='21.734'
                viewBox='0 0 19.781 21.734'
              >
                <g id='Group_2195' transform='translate(-2062.865 -863)'>
                  <path
                    id='Path_6808'
                    d='M2072.4 873.935a5.467 5.467 0 1 0-5.467-5.467 5.473 5.473 0 0 0 5.467 5.467zm0-8.659a3.192 3.192 0 1 1-3.192 3.192 3.2 3.2 0 0 1 3.192-3.192z'
                    className='cls-1'
                    transform='translate(0.353)'
                  />
                  <path
                    id='Path_6809'
                    d='M2064.007 883.795a1.135 1.135 0 0 0 1.126-.989 7.687 7.687 0 0 1 15.246 0 1.137 1.137 0 0 0 1.128.989 1.138 1.138 0 0 0 1.129-1.282 9.965 9.965 0 0 0-19.763.005 1.137 1.137 0 0 0 1.13 1.277z'
                    className='cls-1'
                    transform='translate(0 0.94)'
                  />
                </g>
              </svg>
            </div>
            <div className={classes.tabTitle}>
              <Typography variant='h6' component='p'>
                Profile
              </Typography>
            </div>
          </div>
        </NavLink>
      </ListItem>
      <ListItem className={[classes.issuersList, styles.mb20].join(" ")}>
        <NavLink
          className={classes.navLink}
          to={
            sidebarPathForMobile
              ? `${sidebarPathForMobile}/security`
              : `${path}/security`
          }
          activeClassName={classes.activeLink}
        >
          <div style={{ display: "flex" }}>
            <div className={classes.logoWrapper}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20.589'
                height='24.022'
                viewBox='0 0 20.589 24.022'
              >
                <g
                  id='Group_4397'
                  data-name='Group 4397'
                  transform='translate(-1642.677 -837.865)'
                >
                  <g id='Group_4396' data-name='Group 4396'>
                    <path
                      id='Path_8962'
                      data-name='Path 8962'
                      d='M1652.95,837.865a.854.854,0,0,0-.514.188l-1.681,1.346a17.851,17.851,0,0,1-5.512,3l-1.979.658a.858.858,0,0,0-.586.815v3.687a15.2,15.2,0,0,0,10,14.277.835.835,0,0,0,.291.052h0a.838.838,0,0,0,.292-.052,15.2,15.2,0,0,0,10-14.277v-3.687a.859.859,0,0,0-.586-.815l-1.979-.658a17.856,17.856,0,0,1-5.513-3l-1.68-1.346a.861.861,0,0,0-.558-.188Zm.022,1.957,1.146.917a19.572,19.572,0,0,0,6.042,3.286l1.39.464v3.068a13.561,13.561,0,0,1-2.688,8.036,13.3,13.3,0,0,1-5.891,4.468,13.841,13.841,0,0,1-5.754-4.289,13.13,13.13,0,0,1-2.825-8.215v-3.068l1.39-.464a19.572,19.572,0,0,0,6.042-3.286Z'
                    />
                    <path
                      id='Path_8963'
                      data-name='Path 8963'
                      d='M1652.3,846.558a1.719,1.719,0,0,0-1.719,1.719h0a1.718,1.718,0,0,0,.861,1.488v1.944a.858.858,0,0,0,1.716.025v-1.97a1.718,1.718,0,0,0-.856-3.207Z'
                      transform='translate(0.674 0.741)'
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className={classes.tabTitle}>
              <Typography variant='h6' component='p'>
                Security
              </Typography>
            </div>
          </div>
        </NavLink>
      </ListItem>
    </>
  );
}

const Body = () => {
  const [data, setData] = useState([]);
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );
  const NewIssuer = useSelector((state) => state.issuer.createIssuer.response);
  const NewTemplate = useSelector((state) => state.createTemplate.response);
  const UpdatedTemplate = useSelector((state) => state.updateTemplate.response);
  const deleteIssuerResponse = useSelector(
    (state) => state.issuer.deleteIssuer.response
  );
  const deleteSubjectResponse = useSelector(
    (state) => state.deleteTemplate.response
  );
  const updateIssuer = useSelector(
    (state) => state.issuer.updateIssuer.response
  );
  const { loading } = useSelector((state) => state.midBar);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  let width = useWidth();

  useEffect(() => {
    let url = "/user/issuer";
    let unmounted = false;
    if (
      sidebarPathForMobile
        ? sidebarPathForMobile === "/subjects"
        : path.match("/subjects")
    ) {
      url = "/user/templates";
    } else if (
      sidebarPathForMobile
        ? sidebarPathForMobile === "/settings"
        : path.match("/settings")
    ) {
      return;
    } else if (
      sidebarPathForMobile
        ? sidebarPathForMobile === "/activities"
        : path.match("/activities")
    ) {
      url = "/user/batches";
    }

    const SuccessCB = (list) => {
      setData(list);
    };
    const FailureCB = (error) => {
      setData([]);
    };

    if (!unmounted) {
      dispatch(getList(url, SuccessCB, FailureCB));
    }
    return () => {
      unmounted = true;
    };
  }, [
    dispatch,
    path,
    NewIssuer,
    NewTemplate,
    UpdatedTemplate,
    updateIssuer,
    sidebarPathForMobile,
    deleteIssuerResponse,
    deleteSubjectResponse,
  ]);

  useEffect(() => {
    if (data && data[0]) {
      let { id } = data[0];
      if (pathname === "/credentials") {
        history.push(`${path}/credential-details/${id}`);
        dispatch(selectItem(data[0]));
      } else if (pathname === "/issuers") {
        history.push(`${path}/issuer-details/${id}`);
        dispatch(selectItem(data[0]));
      } else if (pathname === "/subjects") {
        history.push(`${path}/subject-details/${id}`);
        dispatch(selectItem(data[0]));
      } else if (pathname === "/activities") {
        history.push(`${path}/activities-details/${id}`);
        dispatch(selectItem(data[0]));
      }
    }
  }, [data, path, history, pathname, dispatch]);

  const listBody = (data) => {
    let temp = (
      <>
        <CustomTooltip title={data.name}>
          <Typography
            variant='subtitle2'
            component='h4'
            className={classes.listName}
          >
            {data.name}
          </Typography>
        </CustomTooltip>
        <Typography variant='body2' component='p'>
          <MailOutlineIcon fontSize='small' className={classes.mailIcon} />{" "}
          {data.email}
        </Typography>
      </>
    );
    if (path.match("/subjects")) {
      temp = (
        <>
          <CustomTooltip title={data.title}>
            <Typography variant='subtitle2' component='h4'>
              {data.title}
            </Typography>
          </CustomTooltip>
          <Typography variant='body2'>
            {data.issued
              ? `${data.issued} Credentials Issued`
              : "Credentials Not Issued"}
          </Typography>
        </>
      );
    }
    if (path.match("/activities")) {
      temp = (
        <>
          <CustomTooltip title={data.name}>
            <Typography variant='subtitle2' component='h4'>
              {data.name}
            </Typography>
          </CustomTooltip>
          <Typography variant='body2'>{getDate(data.created_at)}</Typography>
        </>
      );
    }
    return temp;
  };

  const handleClick = (event, item) => {
    dispatch(selectItem(item));
  };

  let list = data?.map((data) => {
    let { id, logo, template_image } = data;
    let redirectPath = "";
    if (path.match("/credentials")) {
      redirectPath = `${path}/credential-details/${id}`;
    } else if (path.match("/issuers")) {
      redirectPath = `${path}/issuer-details/${id}`;
    } else if (path.match("/subjects")) {
      redirectPath = `${path}/subject-details/${id}`;
    } else if (path.match("/activities")) {
      redirectPath = `${path}/activities-details/${id}`;
    }
    return (
      <ListItem
        className={[classes.issuersList, styles.mb20].join(" ")}
        key={id}
        onClick={(event) => handleClick(event, data)}
      >
        <NavLink
          className={classes.navLink}
          to={redirectPath}
          activeClassName={classes.activeLink}
        >
          <div className={classes.issusersLogo}>
            <img alt='Logo' src={logo || template_image} />
          </div>
          <div className={classes.issuersDetails}>{listBody(data)}</div>
        </NavLink>
      </ListItem>
    );
  });

  const listBodyForMobile = (data) => {
    let temp = (
      <>
        <CustomTooltip title={data.name}>
          <Typography
            variant='subtitle2'
            component='h4'
            className={classes.listName}
          >
            {data.name}
          </Typography>
        </CustomTooltip>
        <Typography variant='body2' component='p'>
          <MailOutlineIcon fontSize='small' className={classes.mailIcon} />{" "}
          {data.email}
        </Typography>
      </>
    );
    if (sidebarPathForMobile === "/subjects") {
      temp = (
        <>
          <CustomTooltip title={data.title}>
            <Typography variant='subtitle2' component='h4'>
              {data.title}
            </Typography>
          </CustomTooltip>
          <Typography variant='body2'>
            {data.issued
              ? `${data.issued} Credentials Issued`
              : "Credentials Not Issued"}
          </Typography>
        </>
      );
    }
    if (sidebarPathForMobile === "/activities") {
      temp = (
        <>
          <CustomTooltip title={data.name}>
            <Typography variant='subtitle2' component='h4'>
              {data.name}
            </Typography>
          </CustomTooltip>
          <Typography variant='body2'>{getDate(data.created_at)}</Typography>
        </>
      );
    }
    return temp;
  };

  let listForMobile = data?.map((data) => {
    let { id, logo, template_image } = data;
    let redirectPath = "";
    if (sidebarPathForMobile === "/credentials") {
      redirectPath = `${sidebarPathForMobile}/credential-details/${id}`;
    } else if (sidebarPathForMobile === "/issuers") {
      redirectPath = `${sidebarPathForMobile}/issuer-details/${id}`;
    } else if (sidebarPathForMobile === "/subjects") {
      redirectPath = `${sidebarPathForMobile}/subject-details/${id}`;
    } else if (sidebarPathForMobile === "/activities") {
      redirectPath = `${sidebarPathForMobile}/activities-details/${id}`;
    }
    return (
      <ListItem
        className={[classes.issuersList, styles.mb20].join(" ")}
        key={id}
        onClick={(event) => handleClick(event, data)}
      >
        <NavLink
          className={classes.navLink}
          to={redirectPath}
          activeClassName={classes.activeLink}
        >
          <div className={classes.issusersLogo}>
            <img alt='Logo' src={logo || template_image} />
          </div>
          <div className={classes.issuersDetails}>
            {listBodyForMobile(data)}
          </div>
        </NavLink>
      </ListItem>
    );
  });

  let message =
    !data?.length && !loading ? (
      <div className={classes.message}>
        <Typography variant='body1' color='primary'>
          No {path.replace("/", "").replaceAll("-", " ")}
        </Typography>
      </div>
    ) : null;

  // const Row = ({ index, style }) => {
  //   let { id, logo, template_image } = data[index];
  //   let redirectPath = "";
  //   if (path.match("/credentials")) {
  //     redirectPath = `${path}/credential-details/${id}`;
  //   } else if (path.match("/issuers")) {
  //     redirectPath = `${path}/issuer-details/${id}`;
  //   } else if (path.match("/subjects")) {
  //     redirectPath = `${path}/credentials/${id}`;
  //   } else if (path.match("/activities")) {
  //     redirectPath = `${path}/activities-details/${id}`;
  //   }
  //   return (
  //     <ListItem
  //       className={[classes.issuersList, styles.mb20].join(" ")}
  //       key={id}
  //       onClick={(event) => handleClick(event, data[index])}
  //       // style={{ ...style}}
  //     >
  //       <NavLink
  //         className={classes.navLink}
  //         to={redirectPath}
  //         activeClassName={classes.activeLink}
  //       >
  //         <div className={classes.issusersLogo}>
  //           <img alt='Logo' src={logo || template_image} />
  //         </div>
  //         <div className={classes.issuersDetails}>{listBody(data[index])}</div>
  //       </NavLink>
  //     </ListItem>
  //   );
  // };

  let dataList = loading ? (
    <MidBarList />
  ) : width === "xs" ? (
    listForMobile
  ) : (
    list
  );

  let midbarBody;
  if (sidebarPathForMobile) {
    if (sidebarPathForMobile === "/settings") {
      midbarBody = <SettingsBody />;
    } else {
      midbarBody = dataList;
    }
  } else if (path.match("/settings")) {
    midbarBody = <SettingsBody />;
  } else {
    midbarBody = dataList;
  }

  return (
    <>
      <List>{midbarBody}</List>

      {/* {path.match("/settings") ? (
        <List>
          <SettingsBody />
        </List>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              itemCount={data?.length}
              height={height}
              itemSize={90}
              width={width}
              style={{ overflow: "inherit" }}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      )} */}
      {sidebarPathForMobile
        ? sidebarPathForMobile === "/settings"
        : !path.match("/settings")
        ? message
        : null}
    </>
  );
};

export default Body;
