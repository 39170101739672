import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import AppLogo from "components/AppLogo";
import { useRouteMatch, useLocation } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  breadCrumbList: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    fontSize: "10px",
    textTransform: "capitalize",
    color: "#354052",
    "& .gray-link": {
      color: "#6b6b6b",
    },
  },
  headerRight: {
    position: "relative",
    top: "12px",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      marginLeft: 10,
    },
  },
  appTitle: {
    [theme.breakpoints.down("sm")]: {
      "& h1": {
        fontSize: 16,
      },
    },
  },
  link: {
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      textOverflow: "ellipsis",
      maxWidth: 200,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

const PageTitle = () => {
  const [title, setTitle] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const classes = useStyles();
  const { path } = useRouteMatch();
  let { pathname } = useLocation();
  useEffect(() => {
    switch (path) {
      case "/credentials": {
        setTitle("Credentials");
        break;
      }
      case "/issuers": {
        setTitle("Issuer Profiles");
        break;
      }
      case "/subjects": {
        setTitle("Credential Subjects");
        break;
      }
      case "/activities": {
        setTitle("Activities");
        break;
      }
      case "/settings": {
        setTitle("Settings");
        break;
      }
      case "/about": {
        setTitle("About");
        break;
      }
      case "/issuance-process": {
        setTitle("Credential Issuance Process");
        break;
      }
      default:
        break;
    }
  }, [path]);

  useEffect(() => {
    switch (path) {
      case "/settings": {
        setCurrentPath(`Settings`);
        if (pathname.match("/settings/profile")) {
          setCurrentPath("Settings / Profile");
        } else if (pathname.match("/settings/security")) {
          setCurrentPath("Settings / Security");
        }
        break;
      }
      default:
        break;
    }
  }, [pathname, path]);

  let show = path === "/users" ? true : false;
  let logo = show ? <AppLogo customClass="inner-page-logo" /> : null;
  let customTitle = !show ? (
    <div className={classes.appTitle}>
      <Typography variant="h1">{title}</Typography>
    </div>
  ) : null;

  let appPath = !path.match("/issuance-process") ? (
    <div className={classes.breadCrumbList}>
      <Typography
        variant="subtitle2"
        component="p"
        className={`${show ? " " : "gray-link"}`}
      >
        {show ? "dashboard" : null}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography variant="subtitle2" component="p" className={classes.link}>
          {currentPath}
        </Typography>
      </Breadcrumbs>
    </div>
  ) : null;

  return (
    <div className={classes.headerRight}>
      {logo}
      {customTitle}
      {appPath}
    </div>
  );
};
export default React.memo(PageTitle);
