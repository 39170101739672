import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Paper,
  Typography,
  Button,
  IconButton,
  InputLabel,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styles from "core/customstyle.module.css";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import CustomTooltip from "components/CustomTooltip";
import { useDispatch } from "react-redux";
import { userAction } from "pages/DashBoard/dashBoard.action";
import { openNotification } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  semiRoundBorder: {
    borderRadius: 5,
  },
  main: {
    padding: "50px 25px",
    minWidth: 450,
    textAlign: "center",
    borderRadius: 10,
    minHeight: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "30px",
    },
  },
  headingMain: {
    paddingBottom: 20,
  },
  subTitle: {
    color: "#535353",
    lineHeight: "29px",
  },
  closeIconWrapper: {
    top: 5,
    right: 10,
    fontWeight: "bold",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 5,
    },
  },
  heading2: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: "#535353",
    paddingBottom: 15,
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 12,
    },
  },
  redButton: {
    backgroundColor: "#ff313a !important",
    color: "#fff",
    border: "none !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginLeft: 10,
      "& button": {
        width: 95,
      },
    },
  },
  actionButtonWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      "& button": {
        width: 95,
      },
    },
  },
  inputLable: {
    fontSize: "13px",
    fontWeight: "500",
    color: "#616161",
    marginBottom: "8px",
    textTransform: "capitalize",
    "& span": {
      color: "#f00",
    },
  },
  reasons: {
    textAlign: "start",
    padding: "10px 20px",
  },
}));

function RejectUser({ user }) {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.dashBoard.userAction);

  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!Boolean(reason.length)) {
      setError("Please enter a reason for declining request!");
      return;
    }

    const successCB = (message) => {
      dispatch(
        openNotification({
          message,
        })
      );
      setOpen(false);
    };
    const failureCB = (message) => {
      dispatch(
        openNotification({
          message,
          severity: "error",
        })
      );
      setOpen(false);
    };
    if (Boolean(reason.length)) {
      dispatch(
        userAction(
          user.id,
          { status: "decline", decline_message: reason },
          successCB,
          failureCB
        )
      );
    }
  };

  const handleChange = (event) => {
    let { value } = event.target;
    if (Boolean(value.length)) {
      setError("");
    } else {
      setError("Please enter a reason for declining request!");
    }
    setReason(value);
  };

  const handleClose = (event) => {
    setError("");
    setReason("");
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CustomTooltip title="Reject">
        <div
          className={[
            styles.iconWrapper,
            styles.redColorBackground,
            classes.semiRoundBorder,
          ].join(" ")}
          onClick={() => setOpen(true)}
        >
          <CloseIcon className={styles.iconCustom} />
        </div>
      </CustomTooltip>
      <Dialog open={open} onClose={handleClose}>
        <Paper className={classes.main}>
          <IconButton
            className={classes.closeIconWrapper}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.headingMain}>
            <Typography variant="h4" component="p" className={classes.heading2}>
              Reject
            </Typography>
            <Typography variant="subtitle1" className={classes.subTitle}>
              Would You Like to?
            </Typography>
          </div>
          <div className={classes.reasons}>
            <InputLabel htmlFor="fname" className={classes.inputLable} required>
              Decline Reason
            </InputLabel>
            <div>
              <TextField
                variant="outlined"
                type="text"
                fullWidth
                size="small"
                name="revoke-reason"
                value={reason}
                onChange={handleChange}
                required
                error={Boolean(error.length)}
                helperText={error}
              />
            </div>
          </div>
          <div className={classes.actionButtonWrapper}>
            <Button
              variant="contained"
              className={styles.grayButton}
              disabled={loading}
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              className={[classes.redButton, styles.grayButton].join(" ")}
              onClick={handleDelete}
              disabled={loading}
            >
              <Loader show={loading} />
              {!loading ? "Yes" : null}
            </Button>
          </div>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

export default RejectUser;
