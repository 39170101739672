import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "assets/images/svg/everycred-full-logo.svg";

const AppLogo = (props) => {
  const useStyles = makeStyles((theme) => ({
    mainLogo: {
      width: "273px",
      margin: "0 auto 20px",
      "&.inner-page-logo": {
        width: "175px",
        margin: 0,
        "& img": {
          width: "100%",
        },
      },
    },
  }));

  const classes = useStyles();
  return (
    <div
      className={`${classes.mainLogo} ${
        props.customClass ? props.customClass : " "
      }`}
    >
      <img alt="logo" src={Logo} />
    </div>
  );
};

export default AppLogo;
