import React, { useState, useRef, useEffect } from "react";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { getDate, getTime } from "utils";
import theme from "core/Theme";
import styles from "core/customstyle.module.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getRecentlyVerifiedRecords } from "../../header.action";
import PreviewIssuedCredential from "components/CommonActions/Actions/PreviewIssuedCredential";

const useStyles = makeStyles((theme) => ({
  emailCol: {
    minWidth: 150,
    wordBreak: "break-all",
  },
  dateWidth: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 125,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 180,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, label: "Credential Id" },
  { id: "issuer_name", numeric: false, label: "Issuer" },
  { id: "candidate_name", numeric: false, label: "Holder" },
  { id: "last_verified", numeric: false, label: "Verification Date" },
  { id: "ip", numeric: true, label: "IP" },
  { id: "action", numeric: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let newHeadCells = [...headCells];
  return (
    <TableHead>
      <TableRow className={styles.headerRow}>
        {newHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function RecordTable() {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("last_verified");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.recentlyVerified.data);

  useEffect(() => {
    const params = {
      page: page,
      per_page: rowsPerPage,
    };
    dispatch(getRecentlyVerifiedRecords(params));
  }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    handleChangeRowsPerPage(event.target.value);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let emptyTable = (
    <>
      <EnhancedTableHead
        classes={classes}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={7}>
            No Record Found !
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div className={styles.tableRoot}>
          <Paper className={styles.tablePaper} elevation={0}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                {tableData.data ? (
                  <>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={tableData.data.length}
                    />
                    <TableBody>
                      {stableSort(
                        tableData.data,
                        getComparator(order, orderBy)
                      ).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell component="th" id={labelId} scope="row">
                              {row.credential_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.emailCol}
                            >
                              {row.issuer_name}
                            </TableCell>
                            <TableCell align="left">
                              {row.holder_name}
                            </TableCell>
                            <TableCell align="left">
                              {`${getDate(row.created_at)} - 
                              ${getTime(row.created_at)}`}
                            </TableCell>
                            <TableCell align="left">
                              {row.ip_address || "-"}
                            </TableCell>
                            <TableCell align="center">
                              <PreviewIssuedCredential
                                name={row.credential_name}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                ) : (
                  emptyTable
                )}
              </Table>
            </TableContainer>
          </Paper>
          {tableData.data ? (
            <div className={styles.pagination}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <Typography className={styles.showTable}>Show: </Typography> */}
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className={styles.recordBtn}
                >
                  {rowsPerPage} Rows
                  <KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem value="10" onClick={handleClose}>
                              10 Rows
                            </MenuItem>
                            <MenuItem value="15" onClick={handleClose}>
                              15 Rows
                            </MenuItem>
                            <MenuItem value="25" onClick={handleClose}>
                              25 Rows
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Pagination
                count={Math.ceil(tableData.total / rowsPerPage)}
                page={page}
                defaultPage={1}
                onChange={handleChangePage}
                siblingCount={1}
              />
            </div>
          ) : null}
        </div>
      </MuiThemeProvider>
    </>
  );
}
