import React from "react";
import { makeStyles } from "@material-ui/core";
import preLoginBg from "assets/images/svg/pre-login-bg.svg";
import mainPattern from "assets/images/userAuth/mainPattern.png";

const useStyle = makeStyles({
  leftContent: {
    width: "100%",
    maxWidth: 600,
    position: "relative",
    zIndex: 22,
    padding: "0 55px",
    textAlign: "center",
    "@media(max-width: 767px)": {
      padding: "0 16px",
    },
  },
  mainWrapper: {
    backgroundColor: "#fbfcfe",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&::after": {
      content: `' '`,
      position: "absolute",
      backgroundImage: `url(${mainPattern})`,
      backgroundRepeat: "no-repeat",
      top: 0,
      width: "50%",
      height: "100%",
      left: 40,
      backgroundSize: "100%",
      zIndex: 1,
      "@media(max-width: 991px)": {
        width: "100%",
        left: 0,
      },
    },
  },
  preLoginBg: {
  },
  rightContent: {
    "@media(max-width: 991px)": {
      display: "none",
    },
    "& img": {
      display: "block",
      "@media(max-width: 1024px)": {
        width: "100%",
        height: "100%",
      },
    },
  },
});

const AuthBackGround = ({ children }) => {
  const classes = useStyle();
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.leftContent}>{children}</div>
      <div className={classes.rightContent}>
        <img
          alt="laptop-group"
          className={classes.preLoginBg}
          src={preLoginBg}
          height="500"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default AuthBackGround;
