import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Heading from "./components/Heading";
import Action from "./components/Action";
import Body from "./components/Body";
import { useDispatch, useSelector } from "react-redux";
import { clearPathForSidebar } from "../SidebarWithMidbar/action";
import useWidth from "utils/useWidth";

const useStyles = makeStyles((theme) => ({
  midbarContentWrapper: {
    position: "relative",
    height: "calc(100vh - 160px)",
    overflow: "auto",
    top: "0",
    backgroundColor: " #f6f6f6",
    borderRadius: "5px",
    display: "inline-block",
    marginRight: "30px",
    [theme.breakpoints.down("xl")]: {
      width: "320px",
      flex: "0 0 320px",
      padding: "10px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      flex: "0 0 280px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      width: "424px",
      flex: "0 0 424px",
      padding: "10px 32px",
    },
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transparent",
    },
  },
}));

export default function Midbar({ display }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const width = useWidth();
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );



  useEffect(() => {
    if (width !== "xs" && sidebarPathForMobile) {
      dispatch(clearPathForSidebar());
    }
  });
  if (!display) return null;

  return (
    <div className={classes.midbarContentWrapper}>
      <Heading />
      <Action />
      <Body />
    </div>
  );
}
