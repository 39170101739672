import { Select, makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "./components/Table";
import { getUserList } from "./dashBoard.action";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let dropDownOptions = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Accepted",
    value: "accept",
  },
  {
    name: "Rejected",
    value: "decline",
  },
  {
    name: "Pending",
    value: "pending",
  },
];

const DashBoard = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response } = useSelector((state) => state.dashBoard.userList);
  const userAction = useSelector((state) => state.dashBoard.userAction);
  const [userStatus, setUserStatus] = useState("all");

  useEffect(() => {
    const params = {
      status: userStatus,
      role_type: "all",
      page,
      per_page: rowsPerPage,
    };
    dispatch(getUserList(params));
  }, [dispatch, page, rowsPerPage, userAction.response, userStatus]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
  }, []);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
    setRowsPerPage(5);
    setPage(1);
  };

  const dropDownOption = dropDownOptions.map((option) => {
    return (
      <React.Fragment key={option.value}>
        <option value={option.value}>{option.name}</option>
      </React.Fragment>
    );
  });

  return (
    <>
      <Backdrop className={classes.backdrop} open={userAction.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      User Type:{" "}
      <Select
        native
        style={{ marginLeft: 5, minWidth: " 140px" }}
        onChange={handleChange}
        value={userStatus}
        inputProps={{
          name: "user-type",
          id: "user-type-id",
        }}
      >
        {dropDownOption}
      </Select>
      <CommonTable
        records={response?.data}
        total={response?.total}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DashBoard;
