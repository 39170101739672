import { makeStyles, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toBase64 } from "utils";
import {
  addNewTheme,
  getThemes,
  saveEditTheme,
} from "../../themeManagement.action";
import ThemeImageField from "../ThemeImageField";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    "&:first-child": {
      marginTop: "0",
    },
    "& label": {
      textAlign: "left",
      marginBottom: "5px",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 6,
    },
    "& input": {
      [theme.breakpoints.down("lg")]: {
        height: "45px !important",
      },
      [theme.breakpoints.down("sm")]: {
        height: "40px !important",
      },
    },
  },
  customLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#616161",
  },
  errorMsg: {
    color: "red",
    "& p": {
      fontSize: "12px",
      margin: "0 12px",
    },
  },
  saveBtn: {
    padding: "10px 20px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

let initState = { business_user_id: "", theme_name: "", theme_image: [] };
const initErrors = { business_user_id: "", theme_name: "", theme_image: "" };
const fieldKeys = ["business_user_id", "theme_name", "theme_image"];

export default function AddEditTheme({ open, handleClose, editTheme }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEditTheme = !!editTheme?.id;
  const [themeData, setThemeData] = useState(initState);
  const [errors, setErrors] = useState(initErrors);
  const { loading } = useSelector((state) => state.themes.addNewTheme);

  useEffect(() => {
    if (!editTheme?.id) return;
    const editInitState = {
      business_user_id: editTheme?.user_id,
      theme_name: editTheme?.theme_name,
      theme_image: [editTheme?.theme_image],
    };
    setThemeData(editInitState);
  }, [editTheme]);

  const closeDialog = () => {
    setThemeData(initState);
    setErrors(initErrors);
    handleClose();
  };

  const handleThemeData = (ev) => {
    const { name, value } = ev.target;
    setThemeData({ ...themeData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateFields = () => {
    let isValid = true;
    for (const element of fieldKeys) {
      const key = element;
      const value = themeData[key];
      if (!value || (Array.isArray(value) && !value.length)) {
        setErrors((prevState) => {
          return { ...prevState, [key]: "This field is required!!" };
        });
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const isValidForm = validateFields();
    if (!isValidForm) return;
    if (fieldKeys.some((key) => errors[key] !== "")) {
      return;
    }

    let payload = {};

    if (isEditTheme) {
      if (editTheme.user_id !== themeData.business_user_id) {
        payload.business_user_id = Number(themeData.business_user_id);
      }
      if (editTheme.theme_name !== themeData.theme_name) {
        payload.theme_name = themeData.theme_name;
      }
      if (themeData.theme_image[0] !== editTheme.theme_image) {
        payload.theme_image = await toBase64(themeData.theme_image[0]);
      }
    } else {
      payload.business_user_id = Number(themeData.business_user_id);
      payload.theme_name = themeData.theme_name;
      payload.theme_image = await toBase64(themeData.theme_image[0]);
    }

    const successCB = () => {
      closeDialog();
      dispatch(getThemes());
    };

    if (isEditTheme) {
      dispatch(saveEditTheme(editTheme.id, payload, successCB));
      return;
    }
    dispatch(addNewTheme(payload, successCB));
  };

  const isEditDisabled = () => {
    if (!isEditTheme) return false;
    return !!(
      +themeData.business_user_id === +editTheme.user_id &&
      themeData.theme_name === editTheme.theme_name &&
      themeData.theme_image[0] === editTheme.theme_image
    );
  };

  const btnText = isEditTheme ? "Edit Theme" : "Add Theme";

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle onClose={closeDialog}>
        {isEditTheme ? "Edit" : "Add"} Theme
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <div className={classes.fields}>
            <InputLabel className={classes.customLabel}>User Id *</InputLabel>
            <TextField
              type="number"
              name="business_user_id"
              variant="outlined"
              placeholder="Enter Business User Id"
              size="small"
              inputProps={{ min: 0 }}
              value={themeData.business_user_id}
              onChange={handleThemeData}
              required
              fullWidth
              error={!!errors?.business_user_id}
              helperText={errors?.business_user_id}
            />
          </div>
          <div className={classes.fields}>
            <InputLabel className={classes.customLabel}>HTML Name *</InputLabel>
            <TextField
              type="text"
              name="theme_name"
              variant="outlined"
              placeholder="Enter HTML Name"
              size="small"
              value={themeData.theme_name}
              onChange={handleThemeData}
              required
              fullWidth
              error={!!errors?.theme_name}
              helperText={errors?.theme_name}
            />
          </div>
          <div className={classes.fields}>
            <ThemeImageField
              themeData={themeData}
              setThemeData={setThemeData}
              setErrors={setErrors}
              isEditTheme={isEditTheme}
            />
            {errors.theme_image && (
              <div className={classes.errorMsg}>
                <Typography variant="body1">{errors.theme_image}</Typography>{" "}
              </div>
            )}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          disabled={loading || isEditDisabled()}
        >
          <Loader show={loading} />
          {!loading ? btnText : null}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);
