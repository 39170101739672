import { openNotification } from "store/commonActions";
import { headers, http } from "utils";
import {
  ADD_DATA_FIELD_BEGIN,
  ADD_DATA_FIELD_FAILURE,
  ADD_DATA_FIELD_SUCCESS,
  ADD_NEW_THEME_BEGIN,
  ADD_NEW_THEME_FAILURE,
  ADD_NEW_THEME_SUCCESS,
  DELETE_THEME_BEGIN,
  DELETE_THEME_FAILURE,
  DELETE_THEME_SUCCESS,
  EDIT_DATA_FIELD_BEGIN,
  EDIT_DATA_FIELD_FAILURE,
  EDIT_DATA_FIELD_SUCCESS,
  EDIT_THEME_BEGIN,
  EDIT_THEME_FAILURE,
  EDIT_THEME_SUCCESS,
  GET_THEMES_BEGIN,
  GET_THEMES_FAILURE,
  GET_THEMES_SUCCESS,
  GET_THEME_FIELDS_BEGIN,
  GET_THEME_FIELDS_FAILURE,
  GET_THEME_FIELDS_SUCCESS,
  DELETE_DATA_FIELD_BEGIN,
  DELETE_DATA_FIELD_FAILURE,
  DELETE_DATA_FIELD_SUCCESS,
} from "./themeManagement.constant";

// GET THEMES LIST
const getThemesBegin = () => ({ type: GET_THEMES_BEGIN });

const getThemesSuccess = (payload) => ({ type: GET_THEMES_SUCCESS, payload });

const getThemesFailure = (payload) => ({ type: GET_THEMES_FAILURE, payload });

export const getThemes = (params) => (dispatch) => {
  dispatch(getThemesBegin());
  http("get", "/admin/themes", null, { headers, params }, true)
    .then((response) => {
      dispatch(getThemesSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getThemesFailure(error.response?.data?.message));
    });
};

// ADD NEW THEME
const addNewThemeBegin = () => ({ type: ADD_NEW_THEME_BEGIN });

const addNewThemeSuccess = (payload) => ({
  type: ADD_NEW_THEME_SUCCESS,
  payload,
});

const addNewThemeFailure = (payload) => ({
  type: ADD_NEW_THEME_FAILURE,
  payload,
});

export const addNewTheme = (themeData, successCB) => (dispatch) => {
  dispatch(addNewThemeBegin());
  http("post", "/admin/themes", themeData, { headers }, true)
    .then((response) => {
      dispatch(addNewThemeSuccess(response.data.data));
      dispatch(
        openNotification({
          message: response.data.message,
        })
      );
      successCB();
    })
    .catch((error) => {
      dispatch(addNewThemeFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// EDIT THEME
const editThemeBegin = () => ({ type: EDIT_THEME_BEGIN });

const editThemeSuccess = (payload) => ({
  type: EDIT_THEME_SUCCESS,
  payload,
});

const editThemeFailure = (payload) => ({
  type: EDIT_THEME_FAILURE,
  payload,
});

export const saveEditTheme = (themeId, themeData, successCB) => (dispatch) => {
  dispatch(editThemeBegin());
  http("put", "/admin/themes/" + themeId, themeData, { headers }, true)
    .then((response) => {
      dispatch(editThemeSuccess(response.data.data));
      dispatch(
        openNotification({
          message: response.data.message,
        })
      );
      successCB();
    })
    .catch((error) => {
      dispatch(editThemeFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// DELETE THEME
const deleteThemeBegin = () => ({ type: DELETE_THEME_BEGIN });

const deleteThemeSuccess = (payload) => ({
  type: DELETE_THEME_SUCCESS,
  payload,
});

const deleteThemeFailure = (payload) => ({
  type: DELETE_THEME_FAILURE,
  payload,
});

export const deleteTheme = (themeId) => (dispatch) => {
  dispatch(deleteThemeBegin());
  http("delete", "/admin/themes/" + themeId, null, { headers }, true)
    .then((response) => {
      dispatch(deleteThemeSuccess(response.data.data));
      dispatch(
        openNotification({
          message: "Theme deleted successfully!!",
        })
      );
      dispatch(getThemes());
    })
    .catch((error) => {
      dispatch(deleteThemeFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// GET ALL THEME FIELDS
const getThemeFieldsBegin = () => ({ type: GET_THEME_FIELDS_BEGIN });

const getThemeFieldsSuccess = (payload) => ({
  type: GET_THEME_FIELDS_SUCCESS,
  payload,
});

const getThemeFieldsFailure = (payload) => ({
  type: GET_THEME_FIELDS_FAILURE,
  payload,
});

export const getThemeFields = (themeId) => (dispatch) => {
  dispatch(getThemeFieldsBegin());
  http("get", "/admin/themes/fields/" + themeId, null, { headers }, true)
    .then((response) => {
      dispatch(getThemeFieldsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getThemeFieldsFailure(error.response?.data?.message));
    });
};

const addDataFieldBegin = () => ({ type: ADD_DATA_FIELD_BEGIN });

const addDataFieldSuccess = (payload) => ({
  type: ADD_DATA_FIELD_SUCCESS,
  payload,
});

const addDataFieldFailure = (payload) => ({
  type: ADD_DATA_FIELD_FAILURE,
  payload,
});

// ADD NEW THEME DATA FIELD
export const addNewDataField = (fieldData, successCB) => (dispatch) => {
  dispatch(addDataFieldBegin());
  http("post", "/admin/theme/fields", fieldData, { headers }, true)
    .then((response) => {
      dispatch(addDataFieldSuccess(response.data.data));
      dispatch(openNotification({ message: response.data.message }));
      successCB();
    })
    .catch((error) => {
      dispatch(addDataFieldFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// EDIT THEME DATA FIELD
const editDataFieldBegin = () => ({ type: EDIT_DATA_FIELD_BEGIN });

const editDataFieldSuccess = (payload) => ({
  type: EDIT_DATA_FIELD_SUCCESS,
  payload,
});

const editDataFieldFailure = (payload) => ({
  type: EDIT_DATA_FIELD_FAILURE,
  payload,
});

export const editDataField = (fieldId, fieldData, successCB) => (dispatch) => {
  dispatch(editDataFieldBegin());
  http("put", "/admin/themes/fields/" + fieldId, fieldData, { headers }, true)
    .then((response) => {
      dispatch(editDataFieldSuccess(response.data.data));
      dispatch(openNotification({ message: response.data.message }));
      successCB();
    })
    .catch((error) => {
      dispatch(editDataFieldFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// DELETE THEME DATA FIELD
const deleteDataFieldBegin = () => ({ type: DELETE_DATA_FIELD_BEGIN });

const deleteDataFieldSuccess = (payload) => ({
  type: DELETE_DATA_FIELD_SUCCESS,
  payload,
});

const deleteDataFieldFailure = (payload) => ({
  type: DELETE_DATA_FIELD_FAILURE,
  payload,
});

export const deleteDataField = (fieldId, successCB) => (dispatch) => {
  dispatch(deleteDataFieldBegin());
  http("delete", "/admin/themes/fields/" + fieldId, {}, { headers }, true)
    .then((response) => {
      dispatch(deleteDataFieldSuccess(response.data.data));
      dispatch(
        openNotification({ message: "Data field deleted successfully!!" })
      );
      successCB();
    })
    .catch((error) => {
      dispatch(deleteDataFieldFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error.response?.data?.message,
          severity: "error",
        })
      );
    });
};
