import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize:11,
  },
}));

function CustomTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function CustomizedTooltips({ title, children }) {
  return (
    <React.Fragment>
      <CustomTooltip title={title}>{children}</CustomTooltip>
    </React.Fragment>
  );
}
