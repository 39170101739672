export const GET_USER_DETAILS_BEGIN = "GET_USER_DETAILS_BEGIN";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const GET_RECENTLY_VERIFIED_RECORD_BEGIN =
  "GET_RECENTLY_VERIFIED_RECORD_BEGIN";
export const GET_RECENTLY_VERIFIED_RECORD_SUCCESS =
  "GET_RECENTLY_VERIFIED_RECORD_SUCCESS";
export const GET_RECENTLY_VERIFIED_RECORD_FAILURE =
  "GET_RECENTLY_VERIFIED_RECORD_FAILURE";
