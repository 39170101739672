import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import CustomTooltip from "components/CustomTooltip";
import Loader from "components/Loader/Loader";
import styles from "core/customstyle.module.css";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { objectsAreSame } from "utils";
import { deleteDataField, getThemeFields } from "../../themeManagement.action";
import AddEditDataField from "../AddEditDataField/AddEditDataField";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  inputLabel: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "8px",
    color: "#083f85",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  addDataFieldButton: {
    color: "#ffffff",
    backgroundColor: "#083f85",
    borderRadius: "25px",
    padding: "8px 25px",
    textTransform: "none",
    marginLeft: "15px",
    "&:hover": {
      backgroundColor: "#083f85",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 25px",
    },
  },
  contentHeadingWrapper: {
    display: "flex",
    marginBottom: "30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonSpan: {
    margin: "0 0 0 7px",
    fontSize: "14px",
    color: "#ffffff",
  },
  checkbox: {
    "& svg": {
      color: "#083f85",
    },
  },
  deleteIcon: {
    color: "#feaaaa",
  },
  editIconImage: {
    cursor: "pointer",
    float: "left",
  },
  mW100: {
    maxWidth: 100,
    width: 100,
  },
  mW200: {
    maxWidth: 200,
    width: 200,
    whiteSpace: "nowrap",
  },
  mW85: {
    maxWidth: 85,
    width: 85,
  },
  mW300: {
    maxWidth: 300,
    width: 300,
    whiteSpace: "nowrap",
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
  },
  imgWrapper: {
    border: "1px solid",
    padding: "2px 5px",
    borderRadius: 4,
    marginRight: 5,
    cursor: "pointer",
  },
  lightBlueBg: {
    borderColor: "#083f85",
    backgroundColor: "#d7e7fb",
  },
  lightRedBg: {
    borderColor: "#f60000",
    backgroundColor: "#feaaaa",
  },
  lightYellowBg: {
    borderColor: "#ff9100",
    backgroundColor: "#ffe7c8",
  },
  controlTextLength: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  loadingBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const initialValues = {
  fname: "",
  ftype: "",
  sample: "",
  description: "",
  is_mandatory: "",
  preview: "",
  isFieldDisable: false,
  isHolderEditable: "",
};

function DataFieldsTable(props) {
  const {
    isDataFieldEditable = true,
    title = "Data Fields",
    showActions = true,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { themeId } = useParams();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dataField, setDataField] = useState(initialValues);
  const [originalData, setOriginalData] = useState({});
  const fieldIdRef = useRef(null);

  const { response: allDataFields, loading } = useSelector(
    (state) => state.themes.themeFields
  );

  useEffect(() => {
    if (themeId) {
      dispatch(getThemeFields(themeId));
    }
  }, [dispatch, themeId]);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleEditOpen = (row) => {
    const datafield = {
      fname: row.fname.replaceAll("_", " "),
      ftype: row.ftype,
      sample: row.sample,
      description: row.description,
      is_mandatory: row.is_mandatory === true ? "true" : "false",
      preview: row.preview === true ? "true" : "false",
      isHolderEditable: row.is_holder_editable === true ? "true" : "false",
      isFieldDisable: row.fname === "email" || row.fname === "name",
    };
    setDataField(datafield);
    setOriginalData(row);
    setIsEdit(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataField(initialValues);
    setIsEdit(false);
    setOriginalData({});
    if (fieldIdRef.current) {
      fieldIdRef.current = null;
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handleDeleteField = () => {
    const successCB = () => {
      dispatch(getThemeFields(themeId));
      closeDeleteDialog();
    };
    if (fieldIdRef.current) {
      dispatch(deleteDataField(fieldIdRef.current, successCB));
    }
  };

  const handleGoBack = () => history.goBack();

  const datafieldActions = (row) => {
    return isDataFieldEditable === false ? null : (
      <TableCell>
        <div className={classes.iconsContainer}>
          <IconButton onClick={() => handleEditOpen(row)}>
            <RiEdit2Line />
          </IconButton>
          <IconButton
            onClick={() => {
              openDeleteDialog();
              fieldIdRef.current = row.id;
            }}
            className={classes.deleteIcon}
          >
            <RiDeleteBin6Line />
          </IconButton>
        </div>
      </TableCell>
    );
  };

  return (
    <div className={classes.dataFieldMain}>
      <div className={classes.contentHeadingWrapper}>
        <InputLabel htmlFor="template" className={classes.inputLabel}>
          <IconButton onClick={handleGoBack} color="primary">
            <IoMdArrowRoundBack />
          </IconButton>
          {title}
        </InputLabel>
        {isDataFieldEditable ? (
          <Button
            variant="contained"
            className={classes.addDataFieldButton}
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={handleAddOpen}
          >
            <AiOutlinePlus />
            <span className={classes.buttonSpan}>Add New</span>
          </Button>
        ) : null}

        <AddEditDataField
          open={open}
          handleClose={handleClose}
          isEdit={isEdit}
          dataField={dataField}
          originalData={originalData}
        />

        <ConfirmationModal
          open={deleteDialog}
          handleClose={closeDeleteDialog}
          handleConfirm={handleDeleteField}
          title="Delete Data Field"
          message="Are you sure you want to delete this data field?"
        />
      </div>
      <div className={styles.tableRoot}>
        <Paper className={styles.tablePaper} elevation={0}>
          {loading && (
            <div className={classes.loadingBox}>
              <Loader show={loading} />
            </div>
          )}
          {!loading && (
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={styles.headerRow}>
                  <TableRow>
                    <TableCell
                      className={[
                        classes.controlTextLength,
                        classes.mW200,
                      ].join(" ")}
                    >
                      Field
                    </TableCell>
                    <TableCell
                      className={[classes.controlTextLength, classes.mW85].join(
                        " "
                      )}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      className={[
                        classes.controlTextLength,
                        classes.mW200,
                      ].join(" ")}
                    >
                      Sample Data
                    </TableCell>
                    <TableCell
                      className={[
                        classes.controlTextLength,
                        classes.mW300,
                      ].join(" ")}
                    >
                      Description
                    </TableCell>
                    <TableCell align="center" className={classes.mmW100}>
                      Is Mandatory
                    </TableCell>
                    <TableCell className={classes.mmW100} align="center">
                      Preview
                      <Typography
                        variant="body2"
                        component="p"
                        className={styles.fwMedium}
                      >
                        (Show in Summary)
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.mmW100}>
                      Is Holder Editable
                    </TableCell>
                    {showActions && (
                      <TableCell
                        className={classes.mmW100}
                        style={{
                          display:
                            isDataFieldEditable === false
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allDataFields?.map((row) => (
                    <TableRow key={row?.id}>
                      <CustomTooltip title={row?.fname?.replaceAll("_", " ")}>
                        <TableCell
                          className={[
                            classes.controlTextLength,
                            classes.mW200,
                          ].join(" ")}
                          component="th"
                          scope="row"
                        >
                          {row.fname.replaceAll("_", " ")}
                        </TableCell>
                      </CustomTooltip>
                      <CustomTooltip title={checkType(row?.ftype)}>
                        <TableCell
                          className={[
                            classes.controlTextLength,
                            classes.mW85,
                          ].join(" ")}
                        >
                          {checkType(row?.ftype)}
                        </TableCell>
                      </CustomTooltip>
                      <CustomTooltip title={row?.sample}>
                        <TableCell
                          className={[
                            classes.controlTextLength,
                            classes.mW200,
                          ].join(" ")}
                        >
                          {row?.sample}
                        </TableCell>
                      </CustomTooltip>
                      <CustomTooltip title={row?.description}>
                        <TableCell
                          className={[
                            classes.controlTextLength,
                            classes.mW300,
                          ].join(" ")}
                        >
                          {row?.description || "-"}
                        </TableCell>
                      </CustomTooltip>
                      <TableCell align="center">
                        <Checkbox
                          checked={row?.is_mandatory}
                          className={classes.checkbox}
                          disableRipple
                          disabled
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={row?.preview}
                          className={classes.checkbox}
                          disableRipple
                          disabled
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={row?.is_holder_editable}
                          className={classes.checkbox}
                          disableRipple
                          disabled
                        />
                      </TableCell>
                      {showActions && datafieldActions(row)}
                    </TableRow>
                  ))}
                  {!allDataFields?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No Data Fields
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </div>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return objectsAreSame(prevProps, nextProps);
}

export default React.memo(DataFieldsTable, areEqual);

const checkType = (type) => {
  if (type === "integer") {
    return "Integer";
  } else if (type === "datetime") {
    return "Date & Time";
  } else if (type === "date") {
    return "Date";
  } else if (type === "string") {
    return "Text";
  } else if (type === "float") {
    return "Float";
  } else if (type === "boolean") {
    return "Boolean";
  } else if (type === "image") {
    return "Image";
  } else if (type === "url" || type === "image_url" || type === "video_url") {
    return "Link";
  }
};
