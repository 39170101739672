import React from "react";
import styles from "core/customstyle.module.css";
import { ReactComponent as AcceptIcon } from "assets/images/svg/accept-icon.svg";
import { makeStyles } from "@material-ui/core";
import CustomTooltip from "components/CustomTooltip";
import { useDispatch } from "react-redux";
import { openNotification } from "store/commonActions";
import { userAction } from "pages/DashBoard/dashBoard.action";

const useStyles = makeStyles((theme) => ({
  semiRoundBorder: {
    borderRadius: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    color: "#fff",
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    opacity: 0.5,
  },
  draftModal: {
    position: "relative",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const AcceptUser = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAccept = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const successCB = (message) => {
      dispatch(
        openNotification({
          message,
        })
      );
    };
    const failureCB = (message) => {
      dispatch(
        openNotification({
          message,
          severity: "error",
        })
      );
    };

    dispatch(userAction(user.id, { status: "accept" }, successCB, failureCB));
  };

  return (
    <>
      <CustomTooltip title="Accept">
        <div
          className={[
            styles.iconWrapper,
            styles.greenColorBackground,
            classes.semiRoundBorder,
          ].join(" ")}
          onClick={handleAccept}
        >
          <AcceptIcon className={styles.iconCustom} />
        </div>
      </CustomTooltip>
    </>
  );
};

export default React.memo(AcceptUser);
