import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { deleteTheme } from "pages/ThemeManagement/themeManagement.action";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AddEditTheme from "../AddEditTheme/AddEditTheme";

const useStyles = makeStyles((theme) => ({
  themeCard: {
    border: "1px solid #cbcbcb",
    borderRadius: "12px !important",
    position: "relative",
    height: "220px",
    overflow: "hidden",
  },
  actionBtn: {
    position: "absolute",
    right: "15px",
    top: "15px",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#dbdbdb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      minWidth: "0",
    },
  },
  themeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export default function ThemeCard({ theme }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [addEditDialog, setAddEditDialog] = useState(false);
  const [editTheme, setEditTheme] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAddEditDialog = () => {
    setAddEditDialog(true);
  };

  const closeAddEditDialog = () => {
    setAddEditDialog(false);
    setEditTheme(null);
  };

  const handleEditTheme = () => {
    setEditTheme(theme);
    openAddEditDialog();
    handleClose();
  };

  const openDeleteDialog = () => {
    setDeleteDialog(true);
    handleClose();
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handleDeleteTheme = () => {
    dispatch(deleteTheme(theme.id));
  };

  const handleViewTheme = () => {
    if (theme?.id) {
      history.push(`/themes/${theme?.id}/fields`);
    }
  };

  return (
    <div className={classes.themeCard}>
      <div className={classes.actionBtn}>
        <Button onClick={handleClick}>
          <BsThreeDotsVertical />
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleViewTheme}>View</MenuItem>
        <MenuItem onClick={handleEditTheme}>Edit</MenuItem>
        <MenuItem onClick={openDeleteDialog}>Delete</MenuItem>
      </Menu>
      <img src={theme?.theme_image} alt="Theme-Img" className={classes.themeImg} />

      <AddEditTheme
        open={addEditDialog}
        handleClose={closeAddEditDialog}
        editTheme={editTheme}
      />
      <ConfirmationModal
        open={deleteDialog}
        handleClose={closeDeleteDialog}
        handleConfirm={handleDeleteTheme}
        title="Delete Theme"
        message="Are you sure you want to delete this theme?"
      />
    </div>
  );
}
