import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  midbarCard:{
    borderRadius:10,
    marginBottom:20,
    minHeight:100,
    maxWidth: 360,
    [theme.breakpoints.down("xs")]:{
      minHeight:75,
      marginBottom:10,
    },
  },
}));

const MidBarList = () => {
  const classes = useStyles();
  return (
    <div className={classes.midbarCardsContainer}>
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
      <Skeleton className={classes.midbarCard} animation="wave" variant="rect" />
    </div>
  );
};

export default MidBarList;
