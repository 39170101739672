export const GET_THEMES_BEGIN = "GET_THEMES_BEGIN";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const GET_THEMES_FAILURE = "GET_THEMES_FAILURE";

export const ADD_NEW_THEME_BEGIN = "ADD_NEW_THEME_BEGIN";
export const ADD_NEW_THEME_SUCCESS = "ADD_NEW_THEME_SUCCESS";
export const ADD_NEW_THEME_FAILURE = "ADD_NEW_THEME_FAILURE";

export const EDIT_THEME_BEGIN = "EDIT_THEME_BEGIN";
export const EDIT_THEME_SUCCESS = "EDIT_THEME_SUCCESS";
export const EDIT_THEME_FAILURE = "EDIT_THEME_FAILURE";

export const DELETE_THEME_BEGIN = "DELETE_THEME_BEGIN";
export const DELETE_THEME_SUCCESS = "DELETE_THEME_SUCCESS";
export const DELETE_THEME_FAILURE = "DELETE_THEME_FAILURE";

export const GET_THEME_FIELDS_BEGIN = "GET_THEME_FIELDS_BEGIN";
export const GET_THEME_FIELDS_SUCCESS = "GET_THEME_FIELDS_SUCCESS";
export const GET_THEME_FIELDS_FAILURE = "GET_THEME_FIELDS_FAILURE";

export const ADD_DATA_FIELD_BEGIN = "ADD_DATA_FIELD_BEGIN";
export const ADD_DATA_FIELD_SUCCESS = "ADD_DATA_FIELD_SUCCESS";
export const ADD_DATA_FIELD_FAILURE = "ADD_DATA_FIELD_FAILURE";

export const EDIT_DATA_FIELD_BEGIN = "EDIT_DATA_FIELD_BEGIN";
export const EDIT_DATA_FIELD_SUCCESS = "EDIT_DATA_FIELD_SUCCESS";
export const EDIT_DATA_FIELD_FAILURE = "EDIT_DATA_FIELD_FAILURE";

export const DELETE_DATA_FIELD_BEGIN = "DELETE_DATA_FIELD_BEGIN";
export const DELETE_DATA_FIELD_SUCCESS = "DELETE_DATA_FIELD_SUCCESS";
export const DELETE_DATA_FIELD_FAILURE = "DELETE_DATA_FIELD_FAILURE";
