import {
  getRecentlyVerifiedRecordsReducer,
  getUserDetailsReducer,
} from "components/layout/Header/header.reducer";
import midBarReducer from "components/layout/Midbar/midbar.reducer";
import dashBoardReducer from "pages/DashBoard/dashBoard.reducer";
import loginReducer from "pages/Login/Login.reducer";
import themesReducer from "pages/ThemeManagement/themeManagement.reducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import commonReducer, {
  analyticsReducer,
  deleteRecordReducer,
  downloadCredentialReducer,
  getRecordReducer,
  logoutReducer,
  previewDraftRecordReducer,
  revokeCredentialReducer,
  shareCredentialReducer,
  updateRecordReducer,
} from "./commonReducer";

import { sidebarWithMidbarReducer } from "components/layout/SidebarWithMidbar/reducer";

const appReducer = combineReducers({
  common: commonReducer,
  login: loginReducer,
  dashBoard: dashBoardReducer,
  midBar: midBarReducer,
  getUser: getUserDetailsReducer,
  getRecord: getRecordReducer,
  updateRecord: updateRecordReducer,
  deleteRecord: deleteRecordReducer,
  revokeCredential: revokeCredentialReducer,
  shareCredential: shareCredentialReducer,
  logout: logoutReducer,
  previewDraftRecord: previewDraftRecordReducer,
  downloadRecord: downloadCredentialReducer,
  recentlyVerified: getRecentlyVerifiedRecordsReducer,
  sidebarWithMidbar: sidebarWithMidbarReducer,
  analytics: analyticsReducer,
  themes: themesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
