import {
  ADD_DATA_FIELD_BEGIN,
  ADD_DATA_FIELD_FAILURE,
  ADD_DATA_FIELD_SUCCESS,
  DELETE_DATA_FIELD_BEGIN,
  DELETE_DATA_FIELD_FAILURE,
  DELETE_DATA_FIELD_SUCCESS,
  ADD_NEW_THEME_BEGIN,
  ADD_NEW_THEME_FAILURE,
  ADD_NEW_THEME_SUCCESS,
  EDIT_DATA_FIELD_BEGIN,
  EDIT_DATA_FIELD_FAILURE,
  EDIT_DATA_FIELD_SUCCESS,
  EDIT_THEME_BEGIN,
  EDIT_THEME_FAILURE,
  EDIT_THEME_SUCCESS,
  GET_THEMES_BEGIN,
  GET_THEMES_FAILURE,
  GET_THEMES_SUCCESS,
  GET_THEME_FIELDS_BEGIN,
  GET_THEME_FIELDS_FAILURE,
  GET_THEME_FIELDS_SUCCESS,
} from "./themeManagement.constant";

const initState = {
  themesList: {
    loading: false,
    response: [],
    error: "",
  },
  addNewTheme: {
    loading: false,
    response: {},
    error: "",
  },
  editTheme: {
    loading: false,
    response: {},
    error: "",
  },
  themeFields: {
    loading: false,
    response: [],
    error: "",
  },
  addDataField: {
    loading: false,
    response: {},
    error: "",
  },
  editDataField: {
    loading: false,
    response: {},
    error: "",
  },
  deleteDataField: {
    loading: false,
    response: {},
    error: "",
  },
};

const themesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_THEMES_BEGIN:
      return {
        ...state,
        themesList: {
          ...state.themesList,
          loading: true,
        },
      };
    case GET_THEMES_SUCCESS:
      return {
        ...state,
        themesList: {
          ...state.themesList,
          loading: false,
          response: action.payload,
        },
      };
    case GET_THEMES_FAILURE:
      return {
        ...state,
        themesList: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case ADD_NEW_THEME_BEGIN:
      return {
        ...state,
        addNewTheme: {
          ...state.addNewTheme,
          loading: true,
        },
      };
    case ADD_NEW_THEME_SUCCESS:
      return {
        ...state,
        addNewTheme: {
          ...state.addNewTheme,
          loading: false,
          response: action.payload,
        },
      };
    case ADD_NEW_THEME_FAILURE:
      return {
        ...state,
        addNewTheme: {
          loading: false,
          error: action.payload,
          response: {},
        },
      };
    case EDIT_THEME_BEGIN:
      return {
        ...state,
        editTheme: {
          ...state.editTheme,
          loading: true,
        },
      };
    case EDIT_THEME_SUCCESS:
      return {
        ...state,
        editTheme: {
          ...state.editTheme,
          loading: false,
          response: action.payload,
        },
      };
    case EDIT_THEME_FAILURE:
      return {
        ...state,
        editTheme: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case GET_THEME_FIELDS_BEGIN:
      return {
        ...state,
        themeFields: {
          ...state.themeFields,
          loading: true,
        },
      };
    case GET_THEME_FIELDS_SUCCESS:
      return {
        ...state,
        themeFields: {
          ...state.themeFields,
          loading: false,
          response: action.payload,
        },
      };
    case GET_THEME_FIELDS_FAILURE:
      return {
        ...state,
        themeFields: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case ADD_DATA_FIELD_BEGIN:
      return {
        ...state,
        addDataField: {
          ...state.addDataField,
          loading: true,
        },
      };
    case ADD_DATA_FIELD_SUCCESS:
      return {
        ...state,
        addDataField: {
          ...state.addDataField,
          loading: false,
          response: action.payload,
        },
      };
    case ADD_DATA_FIELD_FAILURE:
      return {
        ...state,
        addDataField: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case EDIT_DATA_FIELD_BEGIN:
      return {
        ...state,
        editDataField: {
          ...state.editDataField,
          loading: true,
        },
      };
    case EDIT_DATA_FIELD_SUCCESS:
      return {
        ...state,
        editDataField: {
          ...state.editDataField,
          loading: false,
          response: action.payload,
        },
      };
    case EDIT_DATA_FIELD_FAILURE:
      return {
        ...state,
        editDataField: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    case DELETE_DATA_FIELD_BEGIN:
      return {
        ...state,
        deleteDataField: {
          ...state.deleteDataField,
          loading: true,
        },
      };
    case DELETE_DATA_FIELD_SUCCESS:
      return {
        ...state,
        deleteDataField: {
          ...state.deleteDataField,
          loading: false,
          response: action.payload,
        },
      };
    case DELETE_DATA_FIELD_FAILURE:
      return {
        ...state,
        deleteDataField: {
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    default:
      return state;
  }
};

export default themesReducer;
