import authRoles from "core/auth/authRoles";
import Login from "./Login";

const LoginConfig = {
  component: Login,
  path: ["/", "/login"],
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default LoginConfig;
