import React, { useRef, useEffect } from "react";
import Header from "./Header";
// import Main from "./Main/Main";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Midbar from "./Midbar/Midbar";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainLayoutWrapper: {
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    paddingLeft: "100px",
    paddingBottom: "30px",
    [theme.breakpoints.down("xl")]: {
      paddingLeft: "94px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "106px",
    },
  },
  mainContentWrapper: {
    display: "flex",
  },
  pageContentWrapper: {
    display: "flex",
    padding: "130px 30px 0px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "110px 20px 0px",
    },
  },
  contentWrapperBlock: {
    flex: "1 1 auto",
    minWidth: 0,
    height: "calc(100vh - 190px)",
    overflow: "hidden auto",
    "&::-webkit-scrollbar": {
      display: "block !important",
      width:0,
      height:0,
      background:"transparent"
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  rightContent: {
    paddingLeft: "105px",
    marginTop: "-20%",
  },
}));

function Layout({ isChild, config, children }) {
  const { navbar, sidebar, footer, midbar } = config || {};
  const classes = useStyles();
  const { pathname } = useLocation();
  let mainDivRef = useRef(null);

  useEffect(() => {
    // mainDivRef.current.scrollTo(0, 0);
  }, []);

  let mainContentStyle = {
    paddingLeft: "0",
    marginTop: "0",
  };

  let applyStyle = false;
  if (sidebar?.display) {
    mainContentStyle["paddingLeft"] = classes.rightContent.paddingLeft;
  }
  if (midbar?.display) {
    mainContentStyle["paddingLeft"] = "380px";
  }
  if (
    pathname.includes("/dashboard") ||
    pathname.includes("/about") ||
    pathname.includes("/issuance-process")
  ) {
    applyStyle = true;
  }
  if (isChild) return children;
  return (
    <main className={classes.main}>
      {sidebar.display && (
        <>
          <div className={classes.mainLayoutWrapper}>
            <Header display={navbar?.display} />
            <Sidebar display={sidebar?.display} />
            <div className={classes.pageContentWrapper}>
              <Midbar display={midbar?.display} />
              <div
                className={classes.contentWrapperBlock}
                ref={mainDivRef}
                style={{
                  marginLeft: applyStyle ? "0" : "340",
                }}
              >
                {/* {React.cloneElement(children, {
                  mainDivRef: mainDivRef,
                })} */}
                {children}
              </div>
            </div>
          </div>
          <Footer display={footer?.display} />
        </>
      )}
      {!sidebar.display && <div className='pre-login-screen'>{children}</div>}
    </main>
  );
}

export default Layout;
