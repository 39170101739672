import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Loader from "components/Loader/Loader";
import styles from "core/customstyle.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThemes } from "../../themeManagement.action";
import ThemeCard from "../ThemeCard/ThemeCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function ThemeListing() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response: themeList, loading } = useSelector(
    (state) => state.themes.themesList
  );

  useEffect(() => {
    dispatch(getThemes());
  }, [dispatch]);

  return (
    <Paper className={styles.tablePaper} elevation={0}>
      <div className={classes.root}>
        {loading && (
          <div className={classes.loadingBox}>
            <Loader show={loading} />
          </div>
        )}
        {themeList.length > 0 && !loading && (
          <Grid container spacing={1}>
            {themeList.map((theme) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={theme.id}>
                <ThemeCard theme={theme} />
              </Grid>
            ))}
          </Grid>
        )}
        {!loading && themeList.length === 0 && (
          <div className={classes.loadingBox}>No Theme Found!!</div>
        )}
      </div>
    </Paper>
  );
}
