import {
  LIST_FETCH_BEGIN,
  LIST_FETCH_SUCCESS,
  LIST_FETCH_FAILURE,
  SELECT_ITEM,
  UPDATE_LIST,
} from "./midbar.constant";

const initState = {
  loading: false,
  data: [],
  error: "",
  selectedItem: {},
};

const midBarReducer = (state = initState, action) => {
  switch (action.type) {
    case LIST_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
        selectedItem: {},
      };
    case LIST_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
        selectedItem: {},
      };
    case LIST_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        selectedItem: {},
      };
    case SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case UPDATE_LIST:
      return {
        ...state,
        data: state.data.filter((item) => {
          return item.id !== +action.payload;
        }),
      };
    default:
      return state;
  }
};
export default midBarReducer;
