import React from "react";
import BackgroundWrapper from "components/BackgroundWrapper";
import LoginForm from "./components/LoginForm/LoginForm";

function Login() {
  return (
    <BackgroundWrapper>
      <LoginForm />
    </BackgroundWrapper>
  );
}

export default Login;
