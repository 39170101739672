import {
  LIST_FETCH_BEGIN,
  LIST_FETCH_SUCCESS,
  LIST_FETCH_FAILURE,
  SELECT_ITEM,
  UPDATE_LIST,
} from "./midbar.constant";
import { http, headers } from "utils";

const listFetchBegin = () => ({ type: LIST_FETCH_BEGIN });

const listFetchSuccess = (payload) => ({
  type: LIST_FETCH_SUCCESS,
  payload,
});

const listFetchFailure = (payload) => ({
  type: LIST_FETCH_FAILURE,
  payload,
});

export const getList = (url, SuccessCB) => (dispatch) => {
  dispatch(listFetchBegin());
  http("get", url, null, { headers }, true)
    .then((response) => {
      dispatch(listFetchSuccess(response.data.data));
      SuccessCB(response.data.data);
    })
    .catch((err) => {
      dispatch(listFetchFailure(err.response.data.message));
      // FailureCB(err);
    });
};

export const selectItem = (payload) => ({
  type: SELECT_ITEM,
  payload,
});

export const updateList = (payload) => ({
  type: UPDATE_LIST,
  payload,
});
