import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "components/Loader";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openNotification } from "store/commonActions";
import { LONG_INPUT_LIMIT, SHORT_INPUT_LIMIT, objectsAreSame } from "utils";
import {
  addNewDataField,
  editDataField,
  getThemeFields,
} from "../../themeManagement.action";

const useStyles = makeStyles((theme) => ({
  dialogContentWrapper: {
    minWidth: 520,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
    "& label:not(:first-child)": {
      marginTop: "15px",
    },
    "& input": {
      borderRadius: "4px",
      backgroundColor: "#f6f6f6",
    },
    "& form": {
      padding: "40px 10px 0",
    },
  },
  inputLable: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#616161",
    marginBottom: "8px",
  },
  addButton: {
    color: "#ffffff",
    backgroundColor: "#083f85",
    borderRadius: "25px",
    padding: "8px 80px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "30px",
  },

  dialogPaper: {
    maxHeight: 587,
    "&::-webkit-scrollbar": {
      display: "block",
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#083f85d1",
      borderRadius: 25,
    },
  },
  backdrop: {
    color: "#fff",
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    opacity: 0.5,
  },

  inputProps: {
    padding: "11px 12px",
    backgroundColor: "#f6f6f6",
    border: "solid 0.5px #9f9f9f",
  },
  closeIconWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    fontWeight: "bold",
  },
  addDataFieldModal: {
    position: "relative",
    // height: "700px",
  },
  fieldWrapper2: {
    marginBottom: 5,
    "&.MuiInput-underline:before": {
      height: "auto",
    },
  },
  datePicker: {
    "& input": {
      color: "#616161",
      padding: "10px 15px",
      fontSize: "16px",
      height: 16,
    },
  },
  datePickerClearIcon: {
    position: "absolute",
    right: 0,
  },
  errorHelperText: {
    "&.MuiFormHelperText-root": {
      margin: 0,
      color: "#f44336",
    },
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function AddEditDataField(props) {
  const { open, handleClose, dataField, isEdit, originalData } = props;
  const fieldId = originalData?.id;
  const classes = useStyles();
  const { themeId } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    reset(dataField);
  }, [dataField, reset]);

  const dispatch = useDispatch();
  const { addDataField, editDataField: editDataFieldState } = useSelector(
    (state) => state.themes
  );
  const { loading } = isEdit ? editDataFieldState : addDataField;
  const { response: allDataFields } = useSelector(
    (state) => state.themes.themeFields
  );

  const allFieldsName = allDataFields?.map((item) =>
    item?.fname?.toLowerCase()
  );

  const getOptionList = (options) => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      );
    });
  };

  function handleAddDataField(data) {
    const payload = {
      fname: data.fname.trim().replaceAll(" ", "_"),
      ftype: data.ftype.trim(),
      sample: data.sample.trim(),
      description: data.description.trim(),
      is_mandatory: data.is_mandatory === "true" ? true : false,
      preview: data.preview === "true" ? true : false,
      is_holder_editable: data.isHolderEditable === "true" ? true : false,
      theme_id: +themeId,
    };

    const successCB = () => {
      dispatch(getThemeFields(themeId));
      handleCloseModal();
    };

    if (isEdit) {
      if (
        originalData.fname === "name" ||
        originalData.fname === "email" ||
        originalData.fname === payload.fname
      ) {
        delete payload.fname;
      }

      if (allFieldsName.includes(payload?.fname?.toLowerCase())) {
        dispatch(
          openNotification({
            message: "Field is already in the theme!",
            severity: "error",
          })
        );
      } else {
        dispatch(editDataField(fieldId, payload, successCB));
      }
    } else {
      if (allFieldsName.includes(payload?.fname?.toLowerCase())) {
        dispatch(
          openNotification({
            message: "Field is already in the subject!",
            severity: "error",
          })
        );
      } else {
        dispatch(addNewDataField(payload, successCB));
      }
    }
  }

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.addDataFieldModal}>
        <IconButton
          className={classes.closeIconWrapper}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContentWrapper}>
          <form onSubmit={handleSubmit(handleAddDataField)}>
            <InputLabel htmlFor="fname" className={classes.inputLable} required>
              Field Name (Special characters not allowed)
            </InputLabel>
            <Controller
              name="fname"
              control={control}
              rules={{
                required: "Field Name is required!",
                validate: (value) =>
                  RegExp(/^[^*|":<>[\]{}`\\()';@&#!+=%^&_/.,~?\-$]+$/).test(
                    value
                  ) || "Enter valid field name!",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  placeholder="Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.input}
                  disabled={dataField.isFieldDisable}
                  error={fieldState.invalid}
                  helperText={fieldState?.error?.message || ""}
                  inputProps={{
                    maxLength: SHORT_INPUT_LIMIT,
                  }}
                  InputProps={{
                    endAdornment: `${field.value.length}/${SHORT_INPUT_LIMIT}`,
                  }}
                />
              )}
            />

            <InputLabel htmlFor="ftype" className={classes.inputLable} required>
              Field Type
            </InputLabel>
            <Controller
              name="ftype"
              control={control}
              rules={{
                required: "Field Type is required!",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={
                      field.value !== "" ? undefined : () => "Select field type"
                    }
                    className={classes.input}
                    fullWidth
                    disabled={dataField.isFieldDisable}
                    variant="outlined"
                    error={fieldState.invalid}
                    helpertext={fieldState?.error?.message || ""}
                    MenuProps={MenuProps}
                  >
                    {getOptionList(typeOptions)}
                    <ListSubheader>URL Types</ListSubheader>
                    {getOptionList(urlTypes)}
                  </Select>
                  {fieldState.invalid ? (
                    <FormHelperText className={classes.errorHelperText}>
                      {fieldState?.error?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />
            <InputLabel
              htmlFor="sample"
              className={classes.inputLable}
              required
            >
              Sample Data
            </InputLabel>
            <Controller
              name="sample"
              control={control}
              rules={{
                required: "Sample data is required!",
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  placeholder="Sample Data"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.input}
                  error={fieldState.invalid}
                  helperText={fieldState?.error?.message || ""}
                  inputProps={{
                    maxLength: SHORT_INPUT_LIMIT,
                  }}
                  InputProps={{
                    endAdornment: `${field.value.length}/${SHORT_INPUT_LIMIT}`,
                  }}
                />
              )}
            />

            <InputLabel htmlFor="description" className={classes.inputLable}>
              Description
            </InputLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  placeholder="Description"
                  type="text"
                  variant="outlined"
                  multiline
                  fullWidth
                  row={3}
                  name="description"
                  error={fieldState.invalid}
                  helperText={fieldState?.error?.message || ""}
                  className={classes.input}
                  inputProps={{
                    maxLength: LONG_INPUT_LIMIT,
                  }}
                  InputProps={{
                    endAdornment: `${field.value.length}/${LONG_INPUT_LIMIT}`,
                  }}
                />
              )}
            />

            <InputLabel
              htmlFor="is_mandatory"
              className={classes.inputLable}
              required
            >
              Is Mandatory
            </InputLabel>

            <Controller
              name="is_mandatory"
              control={control}
              rules={{
                required: "Is Mandatory is required!",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={
                      field.value !== ""
                        ? undefined
                        : () => "Select is Mandatory"
                    }
                    className={classes.input}
                    fullWidth
                    disabled={dataField.isFieldDisable}
                    variant="outlined"
                    error={fieldState.invalid}
                    helpertext={fieldState?.error?.message || ""}
                    MenuProps={MenuProps}
                  >
                    {getOptionList(previewAndMandatoryOption)}
                  </Select>
                  {fieldState.invalid ? (
                    <FormHelperText className={classes.errorHelperText}>
                      {fieldState?.error?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />

            <InputLabel
              htmlFor="preview"
              className={classes.inputLable}
              required
            >
              Preview (Show in Summary)
            </InputLabel>
            <Controller
              name="preview"
              control={control}
              rules={{
                required: "Preview Field is required!",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={
                      field.value !== ""
                        ? undefined
                        : () => "Select show preview"
                    }
                    className={classes.input}
                    fullWidth
                    disabled={dataField.isFieldDisable}
                    error={fieldState.invalid}
                    helpertext={fieldState?.error?.message || ""}
                    variant="outlined"
                    MenuProps={MenuProps}
                  >
                    {getOptionList(previewAndMandatoryOption)}
                  </Select>
                  {fieldState.invalid ? (
                    <FormHelperText className={classes.errorHelperText}>
                      {fieldState?.error?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />

            <InputLabel
              htmlFor="isHolderEditable"
              className={classes.inputLable}
              required
            >
              Is holder editable?
            </InputLabel>
            <Controller
              name="isHolderEditable"
              control={control}
              rules={{
                required: "This Field is required!",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={
                      field.value !== ""
                        ? undefined
                        : () => "Select is holder editable"
                    }
                    className={classes.input}
                    fullWidth
                    disabled={dataField.isFieldDisable}
                    error={fieldState.invalid}
                    helpertext={fieldState?.error?.message || ""}
                    variant="outlined"
                    MenuProps={MenuProps}
                  >
                    {getOptionList(isHolderEditableOptions)}
                  </Select>
                  {fieldState.invalid ? (
                    <FormHelperText className={classes.errorHelperText}>
                      {fieldState?.error?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />

            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                type="submit"
                className={classes.addButton}
                disableElevation
                disableFocusRipple
                disableRipple
                disabled={loading || !isDirty}
              >
                <Loader show={loading} />
                {!loading ? (isEdit ? "Update" : "Add") : null}
              </Button>
            </div>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
}

function areEqual(prevProps, nextProps) {
  return objectsAreSame(prevProps, nextProps);
}

export default React.memo(AddEditDataField, areEqual);

const isHolderEditableOptions = [
  { name: "Select", value: "" },
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
];

const previewAndMandatoryOption = [
  { name: "Select", value: "" },
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
];

const typeOptions = [
  { name: "Select", value: "" },
  {
    name: "Numeric",
    value: "integer",
  },
  {
    name: "Text",
    value: "string",
  },
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Date & Time",
    value: "datetime",
  },
  {
    name: "Float",
    value: "float",
  },
  {
    name: "Image",
    value: "image",
  },
];

const urlTypes = [
  {
    name: "Link",
    value: "url",
  },
  {
    name: "Video URL",
    value: "video_url",
  },
  {
    name: "Image URL",
    value: "image_url",
  },
];
