import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Typography from "@material-ui/core/Typography";
// import CustomDropDown from "components/CustomDropDown";
import { useRouteMatch, useHistory } from "react-router-dom";
// import styles from "core/customstyle.module.css";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  actionMain: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      borderRadius: "20px",
      fontSize: "14px",
      width: "130px",
      marginRight: "10px",
      padding: "7px 0",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        marginBottom: 10,
      },
    },
  },
}));

// let dropDownOptions = [
//   {
//     name: "Order By",
//     value: "",
//   },
//   {
//     name: "Name",
//     value: "1",
//   },
//   {
//     name: "Month",
//     value: "2",
//   },
//   {
//     name: "Year",
//     value: "3",
//   },
// ];

const Action = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  const sidebarPathForMobile = useSelector(
    (state) => state.sidebarWithMidbar.response
  );
  const { loading } = useSelector((state) => state.midBar);

  const handleAdd = (event) => {
    if (sidebarPathForMobile) {
      if (sidebarPathForMobile === "/issuers") {
        history.push(`${sidebarPathForMobile}/new-issuer`);
      } else if (sidebarPathForMobile === "/credentials") {
        history.push(`/issuers/new-issuer`);
      } else if (sidebarPathForMobile === "/subjects") {
        history.push(`${sidebarPathForMobile}/new-subject`);
      }
    } else {
      if (path === "/issuers") {
        history.push(`${path}/new-issuer`);
      } else if (path === "/credentials") {
        history.push(`/issuers/new-issuer`);
      } else if (path === "/subjects") {
        history.push(`${path}/new-subject`);
      }
    }
  };

  // let dropDown = null;
  let buttonText = "New Issuer";

  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/subjects"
      : path.match("/subjects")
  ) {
    buttonText = "New Subject";
  }
  let actionButton = (
    <Button
      variant='contained'
      color='primary'
      startIcon={<AddRoundedIcon />}
      // size="small"
      onClick={handleAdd}
      disabled={loading}
    >
      <Typography variant='body1' className={classes.about}>
        {" "}
        {buttonText}{" "}
      </Typography>{" "}
    </Button>
  );

  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/credentials"
      : path.match("/credentials")
  ) {
    // dropDown = (
    //   <CustomDropDown className={styles.dropDown} options={dropDownOptions} />
    // );
  }

  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/activities"
      : path.match("/activities")
  ) {
    actionButton = null;
  }
  if (
    sidebarPathForMobile
      ? sidebarPathForMobile === "/settings"
      : path.match("/settings")
  ) {
    actionButton = null;
  }

  return (
    <div className={classes.actionMain}>
      {actionButton} {/* {dropDown} */}
    </div>
  );
};
export default Action;
